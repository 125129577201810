import { Form, Link, json, redirect } from "react-router-dom";
import { isAPhoneNumber, isAnEmail } from "../../util/fieldChecker";
import PasswordSetupFields from "../../components/passwordSetup/PasswordSetupFields";
import { BASE_URL } from "../../Constants";
import { useState } from "react";
import { Loader } from "../../components/UI/ErrorModal";
import { textFor } from "../../util/languages";
import commonStyles from "../../commonStyles.module.scss";

function SetNewPassword() {
  const [isSubmitting, setIsSubmitting] = useState("");
  const [radioChosen, setRadioChosen] = useState("STEP1");
  const handleRadioChange = (event) => {
    setRadioChosen(event.target.value);
  };
  function getTextForEmailOrSms() {
    if (isAnEmail(localStorage.getItem("phoneOrEmail"))) {
      return textFor("usingEmail") + " ";
    } else {
      return textFor("usingSms") + " ";
    }
  }
  return (
    <div className={commonStyles.pageBackground}>
      {isSubmitting && <Loader />}
      <h2 className={commonStyles.pageTitle}>{textFor("passwordSetup")}</h2>
      <br />
      <input
        id="step1Radio"
        type="radio"
        name="passwordSetupGroup"
        value="STEP1"
        checked={radioChosen === "STEP1"}
        onChange={handleRadioChange}
      />
      <label style={{ fontSize: "5vw" }} for="step1Radio">
        {textFor("step1")}
        <span>
          {getTextForEmailOrSms()}
          <button
            className={commonStyles.greenButton}
            style={{ padding: "6px 5px 5px" }}
            type="button"
            disabled={isSubmitting}
          >
            {textFor("setOtp")}
          </button>
        </span>
      </label>
      <br />
      <br />
      <input
        id="step2Radio"
        type="radio"
        name="passwordSetupGroup"
        value="STEP2"
        checked={radioChosen === "STEP2"}
        onChange={handleRadioChange}
      />
      <label style={{ fontSize: "5vw" }} for="step2Radio">
        {textFor("step2OtpIsSet")}
      </label>
      <br />
      {radioChosen === "STEP2" && (
        <Form method="post">
          <PasswordSetupFields />
        </Form>
      )}
      <div className={commonStyles.bottomSection}>
        <p style={{ color: "white" }}>
          {textFor("alreadyHaveAnAccount?")}
          <Link style={{ color: "blue", marginLeft: "2vw" }} to="/login">
            {textFor("login")}
          </Link>
        </p>
      </div>
    </div>
  );
}
export default SetNewPassword;

export async function action({ request, params }) {
  console.log("SetNewPassword action is called.. request = ", request);
  const data = await request.formData();
  let passwordSetupData = {
    captchaToken: "dummy",
    otp: data.get("otp").trim(),
    password: data.get("password").trim(),
  };
  const userInput = data.get("phoneOrEmail").trim();
  let url = BASE_URL + "/normalUser/";
  if (isAPhoneNumber(userInput)) {
    passwordSetupData.phone = localStorage.getItem("phoneOrEmail");
    url += "resetPasswordWithPhoneOtp";
  } else if (isAnEmail(userInput)) {
    passwordSetupData.email = localStorage.getItem("phoneOrEmail");
    url += "resetPasswordWithEmailOtp";
  } else {
    return null;
  }

  const response = await fetch(url, {
    method: request.method,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(passwordSetupData),
  });

  if (!response.ok) {
    throw json({ message: "Could not setup." }, { status: 500 });
  }
  if (response.ok) {
    console.log("new password Setup response is ok.");
    // localStorage.removeItem("phoneOrEmail");
    // localStorage.removeItem("userId");
    return redirect("/login");
  }
}
