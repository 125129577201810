import { combineReducers } from "redux";
import authReducer from "./authSlice"; // Import your authSlice reducer
import userProfileReducer from "./userProfileSlice";
import familyMembersReducer from "./familyMembersSlice";
import friendsReducer from "./friendsSlice";
import savedGroomListsReducer from "./savedGroomListsSlice";
import searchedGroomReducer from "./searchedGroomSlice";
import contactDetailsReducer from "./contactDetailsSlice";

// Combine all reducers here
const rootReducer = combineReducers({
  auth: authReducer,
  userProfile: userProfileReducer,
  familyMembers: familyMembersReducer,
  friends: friendsReducer,
  savedGroomLists: savedGroomListsReducer,
  searchedGrooms: searchedGroomReducer,
  contactDetails: contactDetailsReducer,
  // Add other reducers if any
});

export default rootReducer;
