import { createSlice } from "@reduxjs/toolkit";
import { loadState } from "../../util/localStorageReader";

let oldSavedFamilyMembers = [];
const stateBeforeRefresh = loadState();
if (Object.keys(stateBeforeRefresh).includes("familyMembers")) {
  let fMembers = stateBeforeRefresh.familyMembers;
  if (Object.keys(fMembers).includes("members")) {
    if (Object.keys(fMembers.members).length > 0) {
      oldSavedFamilyMembers = fMembers.members;
    }
  }
}
const familyMembersSlice = createSlice({
  name: "familyMembers",
  initialState: { members: [...oldSavedFamilyMembers] },
  reducers: {
    fetchMembersSuccess(state, action) {
      const members = action.payload;
      console.log("inside fetchMembersSuccess,action = ", action);
      state.members = members;
    },
    clearFamilyMembers(state, action) {
      state.members = [];
      stateBeforeRefresh.familyMembers = null;
      oldSavedFamilyMembers = [];
    },
    updateFamilyMember(state, action) {
      const updatedMember = action.payload;
      state.members = state.members.map((m) => {
        if (m.id == updatedMember.id) {
          return {
            ...m,
            ...updatedMember,
          };
        }
        return m;
      });
    },
  },
});

export default familyMembersSlice.reducer;
export const { fetchMembersSuccess, clearFamilyMembers, updateFamilyMember } =
  familyMembersSlice.actions;
