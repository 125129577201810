import React from "react";
import styles from "./HelpContent.module.scss";
import { textFor } from "../../util/languages";
import commonStyles from "../../commonStyles.module.scss";
function HelpContent() {
  const helpVideos = [
    {
      id: 1,
      imageUrl:
        "https://www.yadavahir.com/supportPics/stepsToRegisterThumbnail.png",
      targetLink: "https://youtu.be/b3iIO_e8cOg",
      // description: textFor("stepsToRegisterUsingPhone"),
      description: "ऐप में फ़ोन से रजिस्टर करने के स्टेप्स.",
    },
    {
      id: 2,
      imageUrl:
        "https://www.yadavahir.com/supportPics/stepsToRegisterWithEmailThumbnail.png",
      targetLink: "https://youtu.be/5vARh6aCG84",
      // description: textFor("stepsToRegisterUsingEmail"),
      description: "ऐप में ईमेल से रजिस्टर करने के स्टेप्स.",
    },

    {
      id: 3,
      imageUrl:
        "https://www.yadavahir.com/supportPics/stepsToUpdateProfileThumbnail.png",
      targetLink: "https://youtu.be/bVeO8zZwbRU",
      // description: textFor("stepsToUpdateProfileDetails"),
      description: "प्रोफाइल डिटेल्स अपडेट करने के स्टेप्स.",
    },
    {
      id: 4,
      imageUrl:
        "https://www.yadavahir.com/supportPics/stepsToSearchBrideOrGroomThumbnail.png",
      targetLink: "https://youtu.be/R99DThRf8y0",
      // description: textFor("stepsToSearchGroomOrBride"),
      description: "वर या वधू सर्च करने के स्टेप्स.",
    },
  ];

  const openLinkInNewTab = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className={commonStyles.scrollableContainer} style={{ height: "99%" }}>
      <div style={{ color: "black", marginLeft: "3vw" }}>
        <h4>{textFor("dearUser,")}</h4>
        <span>
          <strong>1. {textFor("ifYouSeeAnyImprovement")}</strong>
        </span>
        <br />
        <span>{textFor("forExample:")}</span>
        <br />
        <ul style={{ marginLeft: "6vw" }}>
          <li
            key="1.1"
            className={`${commonStyles.bullet} ${commonStyles.note2}`}
          >
            {textFor("cityOrGotraMissing")}
          </li>
          <li
            key="1.2"
            className={`${commonStyles.bullet} ${commonStyles.note2}`}
          >
            {textFor("spellingMistake")}
          </li>
          <li
            key="1.3"
            className={`${commonStyles.bullet} ${commonStyles.note2}`}
          >
            {textFor("textOnButtonCanBeImproved")}
          </li>
          <li
            key="1.4"
            className={`${commonStyles.bullet} ${commonStyles.note2}`}
          >
            {textFor("objectionableContentPostedByAUser")}
          </li>
        </ul>
        <span>{textFor("thenCallUs")}</span>
        <hr />
        <span>
          <strong>2. {textFor("ifYouWantToContribute")}</strong>
        </span>
        <hr />
        <span>
          <strong>3. {textFor("toReachUs")}</strong>
        </span>
        <ul style={{ marginLeft: "6vw" }}>
          <li key="2.1">
            <i className="fa-solid fa-envelope"> </i>
            <span style={{ marginLeft: "1.5vw" }}>
              {textFor("email:")} info@yadavahir.com
            </span>
          </li>
          <li key="2.2">
            <i className="fa-solid fa-phone"></i>
            <span style={{ marginLeft: "1.5vw" }}>
              {textFor("phone:")} +918717911915 (WhatsApp Only)
            </span>
          </li>
        </ul>
        <span>
          <small>{textFor("atPresentWeAreGettingManyMsgs")}</small>
        </span>
        <hr />
        <span>
          <strong>4. {textFor("referOurYoutubeChannel")}</strong>
          <u
            style={{
              backgroundColor: "blue",
              color: "white",
              padding: "3px 5px",
              cursor: "pointer",
            }}
            onClick={() =>
              openLinkInNewTab("https://www.youtube.com/@YadavAhir-Help")
            }
          >
            {textFor("clickHere")}
          </u>
        </span>
        <br />
        <hr />
        <span>
          <strong>5. {textFor("frequentlyUsedHelpVideos")}</strong>
        </span>

        <div className={styles.helpVideosContainer}>
          <div className={styles.videoContainerGrid}>
            {helpVideos.map((video) => (
              <div className={styles.cardView}>
                <div
                  key={video.id}
                  onClick={() => openLinkInNewTab(video.targetLink)}
                >
                  <img src={video.imageUrl} alt={`Video ${video.id}`} />
                </div>
                <p>{video.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HelpContent;

//todo add filter option of guardianConnection also
