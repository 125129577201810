import { LANGUAGE_KEY } from "../Constants";
let map = new Map();
export async function initLanguageMap() {
  //map starts here
  map.set("6digits", {
    eng: "6 digits",
    hindi: "6 डिजिट्स",
  });

  map.set("BROTHER", {
    eng: "BROTHER",
    hindi: "भाई",
  });
  map.set("BROTHER_IN_LAW", {
    eng: "BROTHER_IN_LAW",
    hindi: "जीजाजी/सालाजी",
  });

  map.set("CLASS_05", {
    eng: "5th class",
    hindi: "पांचवी कक्षा",
  });
  map.set("CLASS_06_09", {
    eng: "6th - 9th class",
    hindi: "छठी - नौवी कक्षा",
  });
  map.set("CLASS_10", {
    eng: "10th class",
    hindi: "दसवीं कक्षा",
  });
  map.set("CLASS_12", {
    eng: "12th class",
    hindi: "बारहवीं कक्षा",
  });
  map.set("CLASS_15_BACHELORS_DEGREE", {
    eng: "Bachelor's Degree",
    hindi: "स्नातक की डिग्री",
  });
  map.set("CLASS_17_MASTERS_DEGREE", {
    eng: "Master's Degree",
    hindi: "स्नातकोत्तर डिग्री",
  });
  map.set("CLASS_20_HIGHER_DEGREE", {
    eng: "Higher Degree",
    hindi: "उच्चतर डिग्री",
  });
  map.set("COUSIN_BROTHER", {
    eng: "COUSIN_BROTHER",
    hindi: "चचेरा/ममेरा भाई",
  });
  map.set("COUSIN_SISTER", {
    eng: "COUSIN_SISTER",
    hindi: "चचेरी/ममेरी बहन",
  });
  map.set("Chandra_Dosha", {
    eng: "Chandra Dosha",
    hindi: "चंद्र दोष",
  });
  map.set("DAUGHTER", {
    eng: "DAUGHTER",
    hindi: "बेटी",
  });
  map.set("DAUGHTER_IN_LAW", {
    eng: "DAUGHTER_IN_LAW",
    hindi: "बहू",
  });
  map.set("DHABAI", {
    eng: "Dhabai",
    hindi: "धबाई",
  });
  map.set("DUBLIYA", {
    eng: "Dubliya",
    hindi: "दुबलिया",
  });
  map.set("ENGLISH", {
    eng: "ENGLISH",
    hindi: "इंग्लिश",
  });
  map.set("FATHER", {
    eng: "FATHER",
    hindi: "पिताजी",
  });
  map.set("FATHER_IN_LAW", {
    eng: "FATHER_IN_LAW",
    hindi: "ससुरजी",
  });
  map.set("FEMALE", {
    eng: "FEMALE",
    hindi: "महिला",
  });
  map.set("FRIEND", {
    eng: "FRIEND",
    hindi: "दोस्त",
  });
  map.set("GAYRIYA", {
    eng: "Gayriya",
    hindi: "गारिया",
  });
  map.set("GHYAM", {
    eng: "Ghyam",
    hindi: "घ्याम",
  });
  map.set("GRAND_DAUGHTER", {
    eng: "GRAND_DAUGHTER",
    hindi: "पोती",
  });
  map.set("GRAND_FATHER", {
    eng: "GRAND_FATHER",
    hindi: "दादाजी",
  });
  map.set("GRAND_MOTHER", {
    eng: "GRAND_MOTHER",
    hindi: "दादीजी",
  });
  map.set("GRAND_SON", {
    eng: "GRAND_SON",
    hindi: "पोता",
  });
  map.set("Guru_Chandal_Dosha", {
    eng: "Guru Chandal Dosha",
    hindi: "गुरु चांडाल दोष",
  });
  map.set("HINDI", {
    eng: "HINDI",
    hindi: "हिंदी",
  });
  map.set("HUSBAND", {
    eng: "HUSBAND",
    hindi: "पति",
  });
  map.set("IHaveCarefullyReadPolicyAndTerms", {
    eng: "I have carefully read and agree to the Privacy Policy and Terms & Conditions given at the bottom.",
    hindi:
      "मैंने नीचे दी गयी नियम एवं शर्तें, प्राइवेसी पॉलिसी अच्छी तरह से पढ़ ली है, मैं सभी शर्तों से सहमत हूँ.",
  });

  map.set("Kaal_Sarpa_Dosha", {
    eng: "Kaal-Sarpa Dosha",
    hindi: "काल सर्प दोष",
  });
  map.set("Kemadruma_Dosha", {
    eng: "Kemadruma Dosha",
    hindi: "केमद्रुम दोष",
  });

  map.set("MALE", {
    eng: "MALE",
    hindi: "पुरुष",
  });
  map.set("MARRIED", {
    eng: "Married",
    hindi: "विवाहित",
  });
  map.set("MATERNAL_AUNT", {
    eng: "MATERNAL_AUNT",
    hindi: "मामीजी",
  });
  map.set("MATERNAL_GRAND_DAUGHTER", {
    eng: "MATERNAL_GRAND_DAUGHTER",
    hindi: "नातिनी",
  });
  map.set("MATERNAL_GRAND_FATHER", {
    eng: "MATERNAL_GRAND_FATHER",
    hindi: "नानाजी",
  });
  map.set("MATERNAL_GRAND_MOTHER", {
    eng: "MATERNAL_GRAND_MOTHER",
    hindi: "नानीजी",
  });
  map.set("MATERNAL_GRAND_SON", {
    eng: "MATERNAL_GRAND_SON",
    hindi: "नाती",
  });
  map.set("MATERNAL_UNCLE", {
    eng: "MATERNAL_UNCLE",
    hindi: "मामाजी",
  });
  map.set("MOTHER", {
    eng: "MOTHER",
    hindi: "माताजी",
  });
  map.set("MOTHER_IN_LAW", {
    eng: "MOTHER_IN_LAW",
    hindi: "सासूजी",
  });
  map.set("Mangal_Dosha", {
    eng: "Mangal Dosha",
    hindi: "मंगल दोष",
  });

  map.set("Max allowed members in a list are: 50", {
    eng: "Max allowed members in a list are: 50.",
    hindi: "लिस्ट में 50 से अधिक मेंबर्स सेव नहीं हो सकते.",
  });
  map.set("Max lists allowed are: 10", {
    eng: "Max lists allowed are: 10",
    hindi: "10 से ज्यादा लिस्ट नहीं बनायी जा सकती.",
  });
  map.set("NEPHEW_BROTHER_SIDE", {
    eng: "NEPHEW_BROTHER_SIDE",
    hindi: "भांजा",
  });
  map.set("NEPHEW_SISTER_SIDE", {
    eng: "NEPHEW_SISTER_SIDE",
    hindi: "भतीजा",
  });

  map.set("NEVER_MARRIED", {
    eng: "Never Married",
    hindi: "अविवाहित",
  });
  map.set("NIECE_BROTHER_SIDE", {
    eng: "NIECE_BROTHER_SIDE",
    hindi: "भाभीजी",
  });
  map.set("NIECE_SISTER_SIDE", {
    eng: "NIECE_SISTER_SIDE",
    hindi: "भतीजी",
  });
  map.set("NO", {
    eng: "NO",
    hindi: "नहीं",
  });
  map.set("Nadi_Dosha", {
    eng: "Nadi Dosha",
    hindi: "नाड़ी दोष",
  });

  map.set("OnlyValidCharsAllowedInEmail", {
    eng: "Only alphabets, digits, hyphen(-), underscore(_), dot(.) and @ allowed in email.",
    hindi:
      "ईमेल में केवल अल्फ़ाबेट, डिजिट, माइनस (-), अंडरस्कोर (_) या @ अक्षर होने चाहिए.",
  });
  map.set("PATERNAL_AUNT", {
    eng: "PATERNAL_AUNT",
    hindi: "चाचीजी",
  });
  map.set("PATERNAL_UNCLE", {
    eng: "PATERNAL_UNCLE",
    hindi: "चाचाजी",
  });

  map.set("Pitra_Dosha", {
    eng: "Pitra Dosha",
    hindi: "पितृ दोष",
  });
  map.set("Rahu_Dosha", {
    eng: "Rahu Dosha",
    hindi: "राहु दोष",
  });
  map.set("SEPARATED_DIVORCED", {
    eng: "Separated / Divorced",
    hindi: "अलग रह रहे / तलाकशुदा",
  });
  map.set("SISTER", {
    eng: "SISTER",
    hindi: "बहन",
  });

  map.set("SON", {
    eng: "SON",
    hindi: "बेटा",
  });
  map.set("SON_IN_LAW", {
    eng: "SON_IN_LAW",
    hindi: "दामादजी",
  });
  map.set("Vyatipata_Dosha", {
    eng: "Vyatipata Dosha",
    hindi: "व्यतिपात दोष",
  });

  map.set("WIDOW_WIDOWER", {
    eng: "Widow / Widower",
    hindi: "विधवा / विधुर",
  });
  map.set("WIFE", {
    eng: "WIFE",
    hindi: "पत्नी",
  });
  map.set("YES", {
    eng: "YES",
    hindi: "हाँ",
  });

  map.set("accept", {
    eng: "ACCEPT",
    hindi: "स्वीकारें",
  });

  map.set("accountCredentials", {
    eng: "Account credentials and usage",
    hindi: "लॉगिन सम्बंधित जानकारी का उपयोग",
  });

  map.set("addBalance", {
    eng: "Add Balance",
    hindi: "ऐड बैलेंस",
  });

  map.set("addFilter", {
    eng: "Add Filter",
    hindi: "फ़िल्टर चुने",
  });

  map.set("addFriend", {
    eng: "Add Friend",
    hindi: "फ्रेंड बनायें",
  });
  map.set("addMember", {
    eng: "Add Member",
    hindi: "ऐड मेंबर",
  });
  map.set("addToList", {
    eng: "Add to List",
    hindi: "लिस्ट में जोड़ें",
  });

  map.set("address", {
    eng: "Address",
    hindi: "एड्रेस",
  });

  map.set("addressIsMissing", {
    eng: "Please select address from profile section.",
    hindi: "कृपया प्रोफाइल ऑप्शन में जाकर एड्रेस सेव करें.",
  });

  map.set("adjustSelectionToCrop", {
    eng: "Adjust selection to crop:",
    hindi: "सिलेक्शन एडजस्ट कर के क्रॉप करें",
  });

  map.set("age", {
    eng: "Age",
    hindi: "उम्र",
  });
  map.set("age18AndShouldKnowLanguages", {
    eng: "You must be at least 18 years old to use the site. You should be able to understand Hindi or English language.",
    hindi:
      "आपकी उम्र कम से कम 18 वर्ष होनी चाहिए| आपको हिंदी या इंग्लिश भाषा का सामान्य ज्ञान होना चाहिये|",
  });
  map.set("alreadyHaveAnAccount?", {
    eng: "Already have an account?",
    hindi: "क्या आप पहले रजिस्टर कर चुके हैं?",
  });
  map.set("amount", {
    eng: "Amt",
    hindi: "राशि",
  });

  map.set("any6DigitNumber", {
    eng: "Any 6 digit number",
    hindi: "6 डिजिट का कोई भी नंबर",
  });
  map.set("anyOther", {
    eng: "Any Other",
    hindi: "कोई अन्य",
  });

  map.set("areYouSure", {
    eng: "Are you sure ?",
    hindi: "पक्का आप ये एक्शन करना चाहते हैं ?",
  });
  map.set("areYouSureToDeleteList", {
    eng: "Are you sure to delete list?",
    hindi: "पक्का आप लिस्ट को डिलीट करना चाहते हैं?",
  });
  map.set("areYouSureToDeleteMemberFromList", {
    eng: "Are you sure to delete person from list?",
    hindi: "पक्का आप व्यक्ति को लिस्ट से डिलीट करना चाहते हैं?",
  });
  map.set("areYouSureToRenameList", {
    eng: "Are you sure to rename list?",
    hindi: "पक्का आप लिस्ट का नाम बदलना चाहते हैं?",
  });
  map.set("atLeast2Letters", {
    eng: "At least 2 letters",
    hindi: "कम से कम 2 अक्षर",
  });
  map.set("atLeast2LettersRequiredForListName", {
    eng: "Invalid Name: Type at least 2 letters.",
    hindi: "गलत नाम - मिनिमम 2 अक्षर टाइप करें",
  });

  map.set("atPresentWeAreGettingManyMsgs", {
    eng: "At present we are getting a lot of emails and messages and we are replying each of them in detail, so, please expect some delay in response from our side, we will try our best to reply to your message, as soon as possible.",
    hindi:
      "वर्तमान में हमारे पास काफी सारे इमेल्स/मैसेजेस आ रहे हैं और हम हर एक ईमेल एवं मैसेज का डीटेल में जवाब दे रहे हैं, तो हो सकता है की हम आपके ईमेल/SMS या फ़ोन का तुरंत जवाब न दे पायें, इसलिए कृपया धैर्य रखें, हम जितनी जल्दी हो सकेगा आपके मैसेज का रिप्लाई करेंगे|",
  });

  map.set("badCredentials", {
    eng: "Invalid phone/email/password. Please retry by correcting details.",
    hindi:
      "उपयोग किये गए फ़ोन/ईमेल/पासवर्ड गलत है, कृपया सही जानकारी डालकर फिर से प्रयास करें.",
  });

  map.set("balanceIsValidTill", {
    eng: "This balance is valid till: ",
    hindi: "बैलेंस वैलिडिटी की तारीख़ है:",
  });

  map.set("balanceValidity", {
    eng: "Validity: 31-May-2025",
    hindi: "वैलिडिटी: 31-May-2025",
  });

  map.set("biggerView", {
    eng: "Bigger view:",
    hindi: "बड़ा फ़ोटो:",
  });
  map.set("birthYear", {
    eng: "Birth Year",
    hindi: "जन्म का वर्ष",
  });

  map.set("block", {
    eng: "BLOCK",
    hindi: "ब्लॉक",
  });
  map.set("blocked", {
    eng: "Blocked",
    hindi: "ब्लॉक्ड",
  });

  map.set("cantSaveLoginAgain", {
    eng: "Your login has expired, so details can't be saved. Please login again and retry...",
    hindi:
      "आपका लॉगिन निरस्त हो चुका है, इसलिए जानकारी सेव नहीं हो पायी, कृपया फिर से लॉगिन करने के बाद ट्राय करें.",
  });

  map.set("change", {
    eng: "Change",
    hindi: "चेंज",
  });
  map.set("changesToPrivacyPolicy", {
    eng: "Changes to this Privacy Policy",
    hindi: "इस प्राइवेसी पॉलिसी में परिवर्तन",
  });

  map.set("choose", {
    eng: "Choose",
    hindi: "चुने",
  });

  map.set("chooseAction", {
    eng: "Choose Action",
    hindi: "एक्शन चुने",
  });

  map.set("chooseCriteriaAgainAndRetry", {
    eng: "Please choose the criteria again and retry search.",
    hindi: "कृपया बैक बटन से पीछे जाकर फिर से सर्च करें.",
  });

  map.set("chooseDistance", {
    eng: "Choose Distance Range.",
    hindi: "दूरी चुने",
  });

  map.set("chooseEducation", {
    eng: "Choose Education",
    hindi: "एजुकेशन का लेवल चुने",
  });

  map.set("chooseGotra", {
    eng: "Choose Gotra",
    hindi: "गोत्र चुने",
  });

  map.set("chooseKundliDosha", {
    eng: "Choose Kundli Dosha",
    hindi: "कुण्डली दोष चुने",
  });

  map.set("chooseLanguage", {
    eng: "Choose Language",
    hindi: "भाषा चुने",
  });

  map.set("chooseMaritalStatus", {
    eng: "Choose Marital Status",
    hindi: "वैवाहिक स्थिति चुने",
  });

  map.set("chooseNearestCity", {
    eng: "Choose Nearest City",
    hindi: "पास का शहर चुने",
  });
  map.set("chooseRelationWithYou", {
    eng: "Choose relation with you",
    hindi: "व्यक्ति से रिश्ता चुने",
  });

  map.set("chooseStateUt", {
    eng: "Choose State/UT",
    hindi: "राज्य या प्रदेश चुने",
  });

  map.set("chooseYearlyIncome", {
    eng: "Choose yearly income",
    hindi: "सालाना इनकम चुने",
  });

  map.set("city", {
    eng: "City",
    hindi: "शहर",
  });

  map.set("cityIsRequired", {
    eng: "City is required:",
    hindi: "शहर का नाम ज़रूरी है:",
  });
  map.set("cityOrGotraMissing", {
    eng: "Name of a city or Gotra is missing in the list,",
    hindi: "लिस्ट में किसी शहर या गोत्र का नाम शामिल ना किया गया हो,",
  });

  map.set("clear", {
    eng: "Clear",
    hindi: "हटायें",
  });
  map.set("clickHere", {
    eng: "clicking here",
    hindi: "यहाँ टच करें",
  });
  map.set("collectOthersPersonalInfoWithoutConsent", {
    eng: "Collect or use any personal information about other users without their expressed consent.",
    hindi:
      "अन्य यूज़र्स की पर्सनल जानकारी का बिना उनकी ज़ाहिर अनुमति के उपयोग करना|",
  });

  map.set("comingSoon", {
    eng: "Coming Soon...",
    hindi: "यह ऑप्शन जल्द ही आ रहा है...",
  });
  map.set("comingSoonMoreSettings", {
    eng: "Coming soon, options to change colors and text size...",
    hindi:
      "बहुत जल्दी अक्षरों की साइज एवं कलर को चेंज करने के ऑप्शंस भी यहाँ पर होंगे...",
  });

  map.set("connected", {
    eng: "Connected",
    hindi: "कनेक्टेड",
  });
  map.set("contact", {
    eng: "Contact",
    hindi: "संपर्क करें",
  });

  map.set("createNewList", {
    eng: "+ Create New List",
    hindi: "+ नयी लिस्ट बनायें",
  });
  map.set("currentBalance:", {
    eng: "Current Balance:",
    hindi: "वर्तमान बैलेंस:",
  });

  map.set("dashboard", {
    eng: "Dashboard",
    hindi: "डेशबोर्ड",
  });
  map.set("dataRetention", {
    eng: "Data retention",
    hindi: "डेटा का रखरखाव",
  });
  map.set("dataSecurityAndAccuracy", {
    eng: "Data security and accuracy",
    hindi: "डेटा की सुरक्षा एवं सटीकता",
  });

  map.set("dearUser,", {
    eng: "Dear User,",
    hindi: "प्रिय यूज़र,",
  });

  map.set("delete", {
    eng: "DELETE",
    hindi: "डिलीट",
  });

  map.set("detailedAddress:", {
    eng: "Detailed Address:",
    hindi: "डिटेल एड्रेस:",
  });

  map.set("details", {
    eng: "Details",
    hindi: "डिटेल्स",
  });
  map.set("detailsForNewEmail", {
    eng: "New email details:",
    hindi: "नये ईमेल की डिटेल्स:",
  });
  map.set("detailsForNewPhone", {
    eng: "New phone details:",
    hindi: "नये फ़ोन की डिटेल्स:",
  });

  map.set("detailsSavedSuccessfully", {
    eng: "Details saved successfully",
    hindi: "आपकी जानकारी सेव हो गयी है.",
  });
  map.set("detailsWeCollectDuringRegistration", {
    eng: "When you register on the site, we collect information such as your name, email address, phone number, year of birth, gender, marital status, caste, occupation, and other relevant details for creating your profile. We also request you to provide same details about your family members.",
    hindi:
      "जब आप साइट पर पंजीकरण करते हैं, तो हम आपकी प्रोफ़ाइल बनाने के लिए आपका नाम, ईमेल पता, फ़ोन नंबर, जन्म का वर्ष, लिंग, वैवाहिक स्थिति, जाति, व्यवसाय और अन्य प्रासंगिक विवरण जैसी जानकारी एकत्र करते हैं। हम आपसे आपके परिवार के सदस्यों के बारे में भी यही विवरण प्रदान करने का अनुरोध करते हैं।",
  });

  map.set("distanceFromYourPlace:", {
    eng: "Distance from your place:",
    hindi: "आपके शहर से दूरी:",
  });

  map.set("distanceRange", {
    eng: "Distance Range",
    hindi: "दूरी की रेंज",
  });

  map.set("dontHaveAnAccount?", {
    eng: "Don't have an account?",
    hindi: "आपने रजिस्ट्रेशन नहीं किया?",
  });
  map.set("dontShareYourAccountWithOthers", {
    eng: "Your account is personal to you and should not be used by anyone else. Pleae do not share your login credetials with anyone else. You are responsible for all activity on the Website that occurs under your password, including any unauthorized use by third parties.",
    hindi:
      " आपके अकाउंट का उपयोग केवल आपके द्वारा ही किया जाना चाहिये| कृपया अपने लॉगिन डिटेल्स किसी अन्य व्यक्ति से साझा न करें| आपके ईमेल/फ़ोन एवं पासवर्ड से लॉगिन करने के बाद, साइट पर किये जाने वाले सारे कामों के लिये आप स्वयं ही ज़िम्मेदार होंगे, चाहे वे काम वास्तव में किसी के भी द्वारा किये गये हों|",
  });
  map.set("dontUseIfDontAgree", {
    eng: "If you don't agree with any of the point in the policy or terms and conditions, please do not use this site. By using this site, you are providing your full consent to all the details we gave in this privacy policy.",
    hindi:
      "यदि आप पॉलिसी या नियमों और शर्तों के किसी भी बिंदु से सहमत नहीं हैं, तो कृपया इस साइट का उपयोग न करें। इस साइट का उपयोग करके, आप इस प्राइवेसी पॉलिसी में दिए गए सभी विवरणों के लिए अपनी पूरी सहमति दे रहे हैं।",
  });

  map.set("dummy", {
    eng: "dummy",
    hindi: "dummy",
  });
  map.set("duringMergerYourInfoMayBeShared", {
    eng: "If we are involved in a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of that transaction.",
    hindi:
      "यदि हम विलय, अधिग्रहण, या हमारी सभी या आंशिक संपत्तियों की बिक्री में शामिल होते हैं, तो आपकी जानकारी उस लेनदेन के हिस्से के रूप में स्थानांतरित की जा सकती है।",
  });
  map.set("edit", {
    eng: "Edit",
    hindi: "एडिट",
  });
  map.set("editList", {
    eng: "Edit List",
    hindi: "एडिट",
  });
  map.set("eduDetailsPlaceholder", {
    eng: "Optional details. 12th(Maths), B.Tech/M.Sc.(Branch), BCA, MBA, etc.",
    hindi:
      "ऑप्शनल डिटेल्स जैसे कि: 12th (मैथ्स/बायो/कॉमर्स), B. Tech./M. Tech.(ब्रांच), BCA, MCA, MBA",
  });
  map.set("education", {
    eng: "Education",
    hindi: "एजुकेशन",
  });

  map.set("education", {
    eng: "Education",
    hindi: "एजुकेशन",
  });
  map.set("eligibilityForUsingThisSite", {
    eng: "Eligibility for using this site",
    hindi: "साइट यूज़ करने के लिए योग्यता",
  });
  map.set("email", {
    eng: "Email",
    hindi: "ईमेल",
  });
  map.set("email:", {
    eng: "Email:",
    hindi: "ईमेल:",
  });
  map.set("emailIdIsCopied", {
    eng: "Email ID is copied.",
    hindi: "ईमेल आई.डी. कॉपी हो गया है.",
  });
  map.set("emailLengthMustBeMore", {
    eng: "Email length must be at least 5.",
    hindi: "ईमेल में 5 या अधिक अक्षर होने चाहिये.",
  });
  map.set("emailPhonePlaceholder", {
    eng: "+910123456789 / a@b.com",
    hindi: "+910123456789 / a@b.com",
  });
  map.set("emailSiteNameIsInvalid", {
    eng: "Email site name is invalid.",
    hindi: "ईमेल में साइट का नाम अमान्य है.",
  });

  map.set("emptyFilters", {
    eng: "Empty Filters...!",
    hindi: "फ़िल्टर खाली है...",
  });

  map.set("family", {
    eng: "Family",
    hindi: "फ़ैमिली",
  });

  map.set("familyMember", {
    eng: "Family Member",
    hindi: "फ़ैमिली मेंबर",
  });

  map.set("familyMembers", {
    eng: "Family Members",
    hindi: "फ़ैमिली मेंबर्स",
  });

  map.set("feet", {
    eng: "Feet",
    hindi: "फ़ीट",
  });
  map.set("forExample:", {
    eng: "For example: ",
    hindi: "उदाहरण के लिए :",
  });

  map.set("forgotPassword?", {
    eng: "Forgot Password?",
    hindi: "पासवर्ड भूल गये?",
  });

  map.set("frequentlyUsedHelpVideos", {
    eng: "Below are links to frequently used help videos:",
    hindi:
      "यहाँ नीचे, ज़्यादातर उपयोग होने वाले कुछ हेल्प वीडियोस की लिंक्स दी गयी है:",
  });
  map.set("friendCount", {
    eng: "Friend count",
    hindi: "मित्रों की संख्या",
  });

  map.set("friends", {
    eng: "Friends",
    hindi: "फ्रेंड्स",
  });

  map.set("friendsAndRequests:", {
    eng: "Friends & Requests:",
    hindi: "फ्रेंड्स & रिक्वेस्ट्स:",
  });

  map.set("fullName", {
    eng: "Full Name",
    hindi: "पूरा नाम",
  });

  map.set("gender", {
    eng: "Gender",
    hindi: "लिंग",
  });

  map.set("gotra", {
    eng: "Gotra",
    hindi: "गोत्र",
  });
  map.set("governingLawAndJurisdiction", {
    eng: "Governing Law and Jurisdiction",
    hindi: "शासी कानून और क्षेत्राधिकार",
  });

  map.set("guardian", {
    eng: "Guardian",
    hindi: "पालक",
  });
  map.set("height", {
    eng: "Height",
    hindi: "हाइट",
  });

  map.set("help", {
    eng: "Help",
    hindi: "हेल्प",
  });
  map.set("howWeShareYourInfo", {
    eng: "How we share your information",
    hindi: "हम आपकी जानकारी इस प्रकार साझा करते हैं",
  });
  map.set("howWeUseYourInfo", {
    eng: "We use your information",
    hindi: "हम आपकी जानकारी का उपयोग इस प्रकार करते हैं",
  });
  map.set("ifYouSeeAnyImprovement", {
    eng: "If you see any scope of improvement in this site,",
    hindi: "यदि इस ऐप या साइट में आपको सुधार की कोई संभावना दिखती है,",
  });
  map.set("ifYouWantToContribute", {
    eng: "If you want to contribute in marketing/testing/development or bug-fixing etc., then please contact us.",
    hindi:
      "यदि आप, इस एप के प्रचार-प्रसार, टेस्टिंग, डेवलपमेंट या बग-फिक्सिंग में कार्य करना चाहते है तो भी हमसे ज़रूर संपर्क करें|",
  });
  map.set("impersonateOrFalselyClaim", {
    eng: "Impersonate any person or entity, or falsely claim an affiliation with any person or entity.",
    hindi:
      "अपनी वास्तविक जानकारी छिपाकर, कोई अन्य व्यक्ति बनकर कार्य करना, या किसी संस्था या व्यक्ति से सम्बंधित होने का झूठा दावा करना|",
  });
  map.set("importantNotice:", {
    eng: "Important Notice:",
    hindi: "ज़रूरी सूचना:",
  });
  map.set("inEmailMax2DotAllowedAfterAd", {
    eng: "In Email, maximum 2 dots(.) allowed after @",
    hindi: "ईमेल में @ के बाद मैक्सिमम 2 डॉट्स(.) होने चाहिये.",
  });

  map.set("inches", {
    eng: "Inches",
    hindi: "इंच",
  });

  map.set("incomeSource", {
    eng: "Income Source",
    hindi: "इनकम साधन",
  });
  map.set("indemnification", {
    eng: "Indemnification",
    hindi: "क्षतिपूर्ति",
  });
  map.set("infoOfSiteIsCopyrighted", {
    eng: "All content on the Site, including but not limited to text, graphics, logos, images, and software, is the property of this site or its licensors and is protected by copyright, trademark, and other intellectual property laws.",
    hindi:
      "साइट पर मौजूद सभी सामग्री, जिसमें टेक्स्ट/शब्द, ग्राफिक्स, लोगो, चित्र और सॉफ्टवेयर शामिल हैं, लेकिन इन्हीं तक सीमित नहीं हैं, इस साइट या इसके लाइसेंसदाताओं की संपत्ति है और कॉपीराइट, ट्रेडमार्क और अन्य बौद्धिक संपदा कानूनों द्वारा संरक्षित है।",
  });
  map.set("infoSecurity", {
    eng: "Information Security",
    hindi: "जानकारियों की सुरक्षा",
  });
  map.set("infoWeCollect", {
    eng: "Information we collect",
    hindi: "हमारे द्वारा एकत्र की जाने वाली जानकारियाँ",
  });
  map.set("informationPrivacy", {
    eng: "Information Privacy",
    hindi: "जानकारियों की गोपनीयता ",
  });
  map.set("intellectualProperty", {
    eng: "Intellectual Property",
    hindi: "बौद्धिक सम्पदा",
  });
  map.set("introduction", {
    eng: "Introduction",
    hindi: "परिचय",
  });

  map.set("invalidCity", {
    eng: "Invalid City.",
    hindi: "शहर का नाम गलत है.",
  });
  map.set("invalidValueForEmail", {
    eng: "Invalid value for email.",
    hindi: "यह ईमेल मान्य नहीं है.",
  });
  map.set("invalidValueForOtp", {
    eng: "Invalid value for OTP.",
    hindi: "यह OTP मान्य नहीं है.",
  });

  map.set("invalidValueForPassword", {
    eng: "This password is invalid.",
    hindi: "यह पासवर्ड मान्य नहीं है.",
  });
  map.set("invalidValueForPhone", {
    eng: "Phone number is invalid.",
    hindi: "यह फ़ोन नंबर मान्य नहीं है.",
  });

  map.set("isPureVeg", {
    eng: "Pure Veg?",
    hindi: "पूर्ण शाकाहारी?",
  });
  map.set("itemsSubmittedSuccessfully", {
    eng: "Items submitted successfully",
    hindi: "Items submitted successfully",
  });

  map.set("kundliDosha", {
    eng: "Kundli Dosha",
    hindi: "कुण्डली दोष",
  });

  map.set("kundliDosha:", {
    eng: "Kundli Dosha :",
    hindi: "कुण्डली दोष :",
  });

  map.set("language", {
    eng: "Language",
    hindi: "भाषा",
  });

  map.set("lastUpdatedOn", {
    eng: "Last updated On",
    hindi: "लेटेस्ट अपडेट का समय",
  });
  map.set("legallyCompetentForMaritalBinding", {
    eng: "The person for whom bide or groom is being searched, must be legally competent to enter into a marital binding contract.",
    hindi:
      "वह व्यक्ति जिसके लिए वर या वधू खोजे जा रहे हैं, उसे क़ानूनी तौर पर विवाह का अधिकार होना चाहिये|",
  });
  map.set("limitationOfLiability", {
    eng: "Limitation of Liability",
    hindi: "लायबिलिटी की परिसीमन",
  });
  map.set("listCreatedSuccessfully", {
    eng: "List is created successfully.",
    hindi: "लिस्ट बना दी गयी हैं.",
  });
  map.set("listRenamedSuccessfully", {
    eng: "List is renamed successfully.",
    hindi: "लिस्ट का नाम बदल दिया गया है.",
  });
  map.set("listSuccessfullyDeleted", {
    eng: "List is successfully deleted.",
    hindi: "लिस्ट डिलीट कर दी गयी है.",
  });
  map.set("loadMore", {
    eng: "Load More",
    hindi: "और देखें",
  });

  map.set("login", {
    eng: "Login",
    hindi: "लॉगिन",
  });

  map.set("loginButton", {
    eng: "Login",
    hindi: "लॉगिन करें",
  });

  map.set("loginDetails", {
    eng: "Login Details",
    hindi: "लॉगिन डिटेल्स",
  });
  map.set("logout", {
    eng: "Logout",
    hindi: "लॉगआउट",
  });

  map.set("maritalStatus", {
    eng: "Marital Status",
    hindi: "वैवाहिक स्थिति",
  });

  map.set("max150Letters", {
    eng: "Max 150 letters",
    hindi: "मैक्सिमम 150 अक्षर",
  });
  map.set("maxLengthIsN", {
    eng: "Maximum allowed length is 50.",
    hindi: "मैक्सिमम 50 अक्षर मान्य है.",
  });

  map.set("memberDeletedSuccessfully", {
    eng: "Member deleted successfully.",
    hindi: "मेंबर की डिटेल्स डिलीट कर दी गयी है.",
  });

  map.set("members", {
    eng: "Members",
    hindi: "मेंबर्स",
  });

  map.set("membersAddedToListSuccessfully", {
    eng: "Selected persons are added to list successfully.",
    hindi: "चुने गये लोगों को लिस्ट में जोड़ दिया गया है.",
  });
  map.set("messageIsCopied", {
    eng: "Message is copied.",
    hindi: "मैसेज कॉपी हो गया है.",
  });

  map.set("minEducation", {
    eng: "Minimum Education",
    hindi: "मिनिमम एजुकेशन",
  });
  map.set("minYearlyIncome", {
    eng: "Minimum Yearly Income",
    hindi: "मिनिमम सालाना इनकम",
  });

  map.set("minimum", {
    eng: "Minimum",
    hindi: "मिनिमम",
  });

  map.set("name", {
    eng: "Name",
    hindi: "नाम",
  });

  map.set("nameIsMandatoryField", {
    eng: "Name is mandatory.",
    hindi: "नाम टाइप करना ज़रूरी है.",
  });
  map.set("nearestCity", {
    eng: "Nearest City",
    hindi: "शहर (जो पास में हो)",
  });
  map.set("newAndOldListNameMustBeDifferent", {
    eng: "Invalid Name: New and old names must be different.",
    hindi: "गलत नाम - नया और पुराना नाम अलग-अलग होना चाहिए.",
  });
  map.set("newListName", {
    eng: "New List Name",
    hindi: "नयी लिस्ट का नाम",
  });

  map.set("newSearch", {
    eng: "New Search",
    hindi: "नयी सर्च",
  });
  map.set("news", {
    eng: "News",
    hindi: "न्यूज़",
  });

  map.set("noFamilyMemberFound", {
    eng: "No record found for any family member.",
    hindi: "आपके फॅमिली मेंबर की कोई भी जानकारी नहीं मिली.",
  });

  map.set("noFieldIsUpdated", {
    eng: "No field is changed.",
    hindi: "कोई भी जानकारी अपडेट नहीं की गयी है.",
  });

  map.set("noFilterSelected", {
    eng: "No filter selected !",
    hindi: "कोई फ़िल्टर नहीं !",
  });

  map.set("noKundliDosh", {
    eng: "No Dosha.",
    hindi: "कोई दोष नहीं.",
  });

  map.set("noPersonFoundInList", {
    eng: "No person found in this list.",
    hindi: "इस लिस्ट में किसी व्यक्ति को सेव नहीं किया गया है",
  });

  map.set("noRecords", {
    eng: "No records...",
    hindi: "कोई रिकॉर्ड नहीं मिला...",
  });

  map.set("noSavedList", {
    eng: "No saved list:",
    hindi: "कोई लिस्ट नहीं मिली:",
  });
  map.set("noWarrantyOfVirus", {
    eng: "We do not warrant that the Site will be uninterrupted or error-free, that defects will be corrected, or that the Site or the server that makes it available are free of viruses or other harmful components.",
    hindi:
      "हम कोई वारंटी नहीं देते की यह साइट हमेशा बिना रुकावट के चलती रहेगी, हम साइट या इसके सर्वर के पूर्ण रूप से वायरस-फ्री होने का दावा नहीं करते|",
  });
  map.set("none", {
    eng: "None",
    hindi: "कोई नहीं",
  });
  map.set("notAllowed", {
    eng: "This action is not allowed.",
    hindi: "यह एक्शन अलाउड नहीं है.",
  });
  map.set("notifyUsImmediatelyForUnauthorizedAccess", {
    eng: "You agree to notify us immediately of any unauthorized use of your account or any other breach of security. Required details about how to inform us are given in help section on the site.",
    hindi:
      "यदि किसी कारण वश आपके अकाउंट का अनाधिकृत उपयोग किया जाता है, या गोपनीय जानकारी की सुरक्षा में कोई चूक होती है, तो आपको हमें तुरंत सूचित करना होगा| हम तक मैसेज भेजने के लिए ज़रूरी जानकारी साइट पर हेल्प सेक्शन में दी गयी है|",
  });
  map.set("objectionableContent", {
    eng: "You upload any type of illegal or objectionable content or media on the site.",
    hindi: "आप साइट पर कोई भी आपत्तिजनक जानकारी या फोटो अपलोड करते हैं|",
  });
  map.set("objectionableContentPostedByAUser", {
    eng: "Obscene or Objectionable content is posted by some other users,",
    hindi: "किसी अन्य यूजर की जानकारी या फोटो आपत्तिजनक हो,",
  });
  map.set("onlyOneAdAllowedInEmail", {
    eng: "Only one @ is allowed.",
    hindi: "ईमेल में केवल एक @ होना चाहिये.",
  });

  map.set("openCamera", {
    eng: "Open Camera",
    hindi: "ओपन कैमरा",
  });

  map.set("openGallery", {
    eng: "Open Gallery",
    hindi: "ओपन गैलरी",
  });

  map.set("optionalDetailsAboutAnyOtherThing", {
    eng: "Optional details about any other thing.",
    hindi: "और कोई भी जानकारी जो आप देना चाहें.",
  });
  map.set("optionalDetailsAboutSpecialAbility", {
    eng: "Optional details like: One eye, blind, deaf, one legged etc.",
    hindi:
      "ऑप्शनल डिटेल्स जैसे कि: देखने में बाधा, सुनने में बाधा, चलने में बाधा, आदि.",
  });
  map.set("optionalIncomeSourceDetails", {
    eng: "Optional details like: Job, Business, Farming etc.",
    hindi: "ऑप्शनल डिटेल्स जैसे कि : जॉब, बिज़नेस, फार्मिंग आदि.",
  });

  map.set("or", {
    eng: "or",
    hindi: "या",
  });

  map.set("otherDetails", {
    eng: "Other Details",
    hindi: "अन्य डिटेल्स",
  });

  map.set("otherWillSeeYourDetails", {
    eng: "When other users will search for groom or bride, then your profile and member details will be shown to them according to the specified search filters.",
    hindi:
      "जब दूसरे यूजर वर या वधू सर्च करेंगे तब उनके द्वारा चुने गए सर्च फ़िल्टर के अनुसार आपकी प्रोफ़ाइल एवं मेंबर्स की जानकारी उन्हें दिखायी जायेगी.",
  });

  map.set("otp", {
    eng: "OTP",
    hindi: "OTP",
  });
  map.set("otpMustBeOf6Digits", {
    eng: "OTP must have 6 digits.",
    hindi: "OTP में 6 डिजिट्स होने चाहिये.",
  });

  map.set("password:", {
    eng: "Password:",
    hindi: "पासवर्ड:",
  });

  map.set("passwordPlaceholder", {
    eng: "8 letters, Capital, Small and digits",
    hindi: "मिनीमम 8 अक्षर, बड़े छोटे एवं नंबर",
  });

  map.set("passwordReset", {
    eng: "Password Reset",
    hindi: "पासवर्ड रीसेट",
  });
  map.set("passwordSetting", {
    eng: "Password setting:",
    hindi: "पासवर्ड सेटिंग:",
  });
  map.set("passwordSetup", {
    eng: "Password Setup",
    hindi: "पासवर्ड सेट-अप",
  });

  map.set("passwordUpdatedSucessfully", {
    eng: "Your password has updated successfully.",
    hindi: "आपका पासवर्ड अपडेट हो गया है.",
  });
  map.set("pending", {
    eng: "Pending",
    hindi: "पेंडिंग",
  });
  map.set("pendingNewEmail:", {
    eng: "Pending new email:",
    hindi: "पेंडिंग नया ईमेल:",
  });
  map.set("pendingNewPhone:", {
    eng: "Pending new phone:",
    hindi: "पेंडिंग नया फ़ोन:",
  });

  map.set("perResultChargesMessage", {
    eng: "Charges = Rs 1 for each result.",
    hindi: "चार्ज : 1 रु प्रति सर्च रिजल्ट.",
  });

  map.set("personDetails:", {
    eng: "Person Details:",
    hindi: "व्यक्ति की जानकारी:",
  });
  map.set("personIsDeletedFromList", {
    eng: "Person is deleted from list.",
    hindi: "व्यक्ति को लिस्ट से डिलीट कर दिया गया है",
  });

  map.set("personalDetails", {
    eng: "Personal Details",
    hindi: "पर्सनल डिटेल्स",
  });
  map.set("phone", {
    eng: "Phone",
    hindi: "फ़ोन",
  });
  map.set("phone:", {
    eng: "Phone:",
    hindi: "फ़ोन:",
  });
  map.set("phoneLengthMustBe12", {
    eng: "Phone number length must be 12.",
    hindi: "फ़ोन नंबर में 12 डिजिट्स होने चाहिये.",
  });
  map.set("phoneMustStartsWith91", {
    eng: "Phone number must start with +91.",
    hindi: "फ़ोन नंबर +91 से शुरू होना चाहिये.",
  });
  map.set("phoneNumberIsCopied", {
    eng: "Phone number is copied.",
    hindi: "फ़ोन नंबर कॉपी हो गया है.",
  });

  map.set("phoneOrEmail:", {
    eng: "Phone or Email:",
    hindi: "फ़ोन या ईमेल:",
  });

  map.set("phoneUpdatedSuccessfully", {
    eng: "Your phone number has updated successfully.",
    hindi: "आपका फ़ोन नंबर अपडेट हो गया है.",
  });
  map.set("pleaseAcceptTermsAndPolicy", {
    eng: "Please accept the checkbox about Terms & Condition and Privacy Policy.",
    hindi:
      "कृपया नियम एवं शर्ते और प्राइवेसी पॉलिसी से सम्बंधित चेकबॉक्स को सेलेक्ट करें.",
  });

  map.set("pleaseAddAtLeastOneFilter", {
    eng: "Please add at lease one filter.",
    hindi: "कृपया एक फ़िल्टर ज़रूर चुने.",
  });

  map.set("pleaseChooseAList", {
    eng: "Please choose a list.",
    hindi: "कृपया कोई लिस्ट चुने.",
  });

  map.set("pleaseChooseDistance", {
    eng: "Please choose Distance Range.",
    hindi: "कृपया फ़िल्टर में दूरी चुने",
  });

  map.set("pleaseChooseGender", {
    eng: "Please choose gender.",
    hindi: "कृपया फ़िल्टर में लिंग चुने",
  });

  map.set("pleaseChooseGotra", {
    eng: "Please choose gotra.",
    hindi: "कृपया फ़िल्टर में गोत्र चुने",
  });

  map.set("pleaseChooseKundliDosha", {
    eng: "Please choose kundli dosha.",
    hindi: "कृपया फ़िल्टर में कुण्डली दोष चुने",
  });

  map.set("pleaseChooseMaritalStatus", {
    eng: "Please choose marital status.",
    hindi: "कृपया फ़िल्टर में वैवाहिक स्थिति चुने",
  });

  map.set("pleaseChooseMaxAge", {
    eng: "Please choose maximum age.",
    hindi: "कृपया फ़िल्टर में मैक्सिमम उम्र चुने",
  });

  map.set("pleaseChooseMaxHeightFeet", {
    eng: "Please choose maximum height in feet.",
    hindi: "कृपया फ़ीट में मैक्सिमम हाईट चुने",
  });

  map.set("pleaseChooseMaxHeightInches", {
    eng: "Please choose maximum height in inches.",
    hindi: "कृपया इंच में मैक्सिमम हाईट चुने",
  });

  map.set("pleaseChooseMaxWeight", {
    eng: "Please choose maximum weight.",
    hindi: "कृपया इंच में मैक्सिमम वज़न चुने",
  });

  map.set("pleaseChooseMinAge", {
    eng: "Please choose minimum age.",
    hindi: "कृपया फ़िल्टर में मिनिमम उम्र चुने",
  });

  map.set("pleaseChooseMinEducation", {
    eng: "Please choose minimum education.",
    hindi: "कृपया फ़िल्टर में एजुकेशन चुने",
  });

  map.set("pleaseChooseMinHeightFeet", {
    eng: "Please choose minimum height in feet.",
    hindi: "कृपया फ़ीट में मिनिमम हाईट चुने",
  });

  map.set("pleaseChooseMinHeightInches", {
    eng: "Please choose minimum height in inches.",
    hindi: "कृपया इंच में मिनिमम हाईट चुने",
  });

  map.set("pleaseChooseMinWeight", {
    eng: "Please choose minimum weight.",
    hindi: "कृपया मिनिमम वज़न चुने",
  });

  map.set("pleaseChoosePureVeg", {
    eng: "Please choose pure veg option.",
    hindi: "कृपया पूर्ण शाकाहारी संबंधित ऑप्शन चुने",
  });

  map.set("pleaseChooseSpecialAbility", {
    eng: "Please choose special ability.",
    hindi: "कृपया दिव्यांगता चुने",
  });

  map.set("pleaseChooseYearlyIncome", {
    eng: "Please choose yearly income.",
    hindi: "कृपया सालाना इनकम चुने",
  });
  map.set("pleaseCompleteOrDeletePendingEmail", {
    eng: "Please complete/delete pending email",
    hindi: "कृपया पहले से पेंडिंग ईमेल को वेरीफाई या डिलीट करें.",
  });
  map.set("pleaseCompleteOrDeletePendingPhone", {
    eng: "Please complete/delete pending phone",
    hindi: "कृपया पहले से पेंडिंग फ़ोन को वेरीफाई या डिलीट करें.",
  });

  map.set("pleaseConfirm", {
    eng: "Please confirm",
    hindi: "कृपया कन्फर्म करें",
  });
  map.set("pleaseCreateAndChooseAList", {
    eng: "Please create a list and select that.",
    hindi: "कृपया नयी लिस्ट बना कर उसे चुने.",
  });
  map.set("pleaseEnterOtpPassword:", {
    eng: "Please enter OTP and Password:",
    hindi: "कृपया OTP एवं पासवर्ड टाइप करें:",
  });

  map.set("pleaseLoginAgainToMakeSettingsEffective", {
    eng: "Your settings are changed, please login again to start using new settings.",
    hindi:
      "आपकी सेटिंग्स सेव हो गयी है, कृपया नयी सेटिंग्स का उपयोग करने के लिए फिर से लॉगिन करें.",
  });

  map.set("pleaseProvideAddressCity", {
    eng: "Please provide address city from Profile section.",
    hindi:
      "कृपया यूज़र प्रोफाइल में जाकर एड्रेस में राज्य एवं शहर का नाम सेव करें.",
  });
  map.set("pleaseReadCarefully", {
    eng: "Please read carefully",
    hindi: "कृपया ध्यान से पढ़ें",
  });

  map.set("pleaseSelectAmount", {
    eng: "Please select amount.",
    hindi: "कृपया राशि सिलेक्ट करें.",
  });

  map.set("pleaseTypeAtLeast2CharForName", {
    eng: "Please type at least 2 letters for name.",
    hindi: "कृपया नाम के मिनिमम 2 अक्षर टाइप करें",
  });

  map.set("plsUseDifferentSearchFilter", {
    eng: "Please use different search filter.",
    hindi: "कृपया किसी दूसरे सर्च फ़िल्टर का उपयोग करें.",
  });
  map.set("pressBackAndGoBack", {
    eng: "Press back button and go back.",
    hindi: "बैक बटन टच कर के पिछले पेज पर जाएँ.",
  });

  map.set("pressOkToGetBalance", {
    eng: "Press OK to get FREE balance of Rs. ",
    hindi: "OK टच करके आपको बैलेंस में मिलेंगे Rs. ",
  });
  map.set("privacyPolicy", {
    eng: "Privacy Policy",
    hindi: "प्राइवेसी पॉलिसी",
  });
  map.set("privacyPolicyDescription", {
    eng: "This Privacy Policy describes how this web site (Hereafter referred as web app, site, we, us or our) collects, uses, and shares personal information of users.",
    hindi:
      "यह प्राइवेसी पॉलिसी बताती है कि यह वेबसाइट (जिसे इसके बाद वेब ऐप, साइट, हम, हमें या हमारा कहा जाएगा) उपयोगकर्ताओं की व्यक्तिगत जानकारी को कैसे एकत्र, उपयोग और साझा करती है।  ",
  });
  map.set("privacyPolicyUpdatedOn", {
    eng: "Updated on: 01-Dec-2024",
    hindi: "अपडेट की तारीख : 01-Dec-2024",
  });

  map.set("profile", {
    eng: "Profile",
    hindi: "प्रोफ़ाइल",
  });

  map.set("profilePreview:", {
    eng: "Profile Preview:",
    hindi: "प्रोफाइल प्रीव्यू:",
  });

  map.set("promotionalOffer", {
    eng: "Promotional Offer!",
    hindi: "प्रमोशनल ऑफर!",
  });
  map.set("provideTruthfulInfo", {
    eng: "You must provide accurate and truthful information during registration and while using the site, about yourself and your family members.",
    hindi:
      " रजिस्ट्रेशन करते समय एवं लॉगिन करने के बाद, साइट पर आपको अपने एवं अपने फैमिली मैंबर्स के विषय में हमेशा पूर्ण रूप से सही जानकारी डालनी चाहिये|",
  });

  map.set("pureVeg", {
    eng: "Pure Veg",
    hindi: "पूर्ण शाकाहारी",
  });
  map.set("quicklyAfterEmailRequest", {
    eng: "within 30 minutes from the request, to",
    hindi: "रिक्वेस्ट के 30 मिनट के अंदर,",
  });
  map.set("quicklyAfterPhoneRequest", {
    eng: "within 30 minutes from the request, to",
    hindi: "रिक्वेस्ट के 30 मिनट के अंदर,",
  });
  map.set("referOurYoutubeChannel", {
    eng: "To se demo of various features of this app/site, please visit our YouTube channel by ",
    hindi:
      "इस ऐप/साइट के विभिन्न ऑप्शंस के डेमो वीडियोस हमारे YouTube चैनल पर देखने के लिये ",
  });
  map.set("referToOurPrivacyPolicy", {
    eng: "Please refer to our separately provided Privacy Policy for details on how we collect, use, and disclose your personal information.",
    hindi:
      "आपकी व्यक्तिगत जानकारी को हम कैसे एकत्र करते हैं, उपयोग करते हैं, और प्रकट करते हैं, इस बारे में विवरण के लिए कृपया अलग से दी गयी हमारी प्राइवेसी पॉलिसी देखें।",
  });

  map.set("refresh", {
    eng: "Refresh",
    hindi: "रिफ्रेश",
  });

  map.set("registerButton", {
    eng: "Register",
    hindi: "रजिस्टर करें",
  });

  map.set("registration", {
    eng: "Registration",
    hindi: "रजिस्ट्रेशन",
  });
  map.set("registrationStarted", {
    eng: "Your registration is started.",
    hindi: "आपका रजिस्ट्रेशन स्टार्ट हो गया है.",
  });

  map.set("reject", {
    eng: "REJECT",
    hindi: "रिजेक्ट",
  });
  map.set("rejected", {
    eng: "Rejected",
    hindi: "रिजेक्टेड",
  });

  map.set("relation", {
    eng: "Relation",
    hindi: "रिश्ता",
  });
  map.set("rename", {
    eng: "Rename",
    hindi: "नाम बदलें",
  });

  map.set("resend", {
    eng: "RESEND",
    hindi: "रीसेंड",
  });
  map.set("reset", {
    eng: "Reset",
    hindi: "रीसेट",
  });

  map.set("resetIt", {
    eng: "Reset it",
    hindi: "रीसेट करें",
  });
  map.set("resetRequestAccepted", {
    eng: "Your password reset request is accepted.",
    hindi: "आपकी पासवर्ड रीसेट रिक्वेस्ट स्वीकार कर ली गयी है.",
  });

  map.set("resetWillBeDoneInFewHours", {
    eng: "After that, your password will be reset within 3-4 hours.",
    hindi: "उसके बाद, आपका पासवर्ड 3-4 घंटे के अंदर रीसेट कर दिया जाएगा.",
  });

  map.set("savedList", {
    eng: "Saved List:",
    hindi: "सेव की हुई लिस्ट:",
  });

  map.set("savedLists", {
    eng: "Saved Lists:",
    hindi: "सेव की हुई लिस्ट्स:",
  });

  map.set("search", {
    eng: "Search",
    hindi: "सर्च",
  });
  map.set("searchBride", {
    eng: "Search Bride",
    hindi: "वधू खोजें",
  });

  map.set("searchCriteria", {
    eng: "Search criteria:",
    hindi: "सर्च फिल्टर्स:",
  });
  map.set("searchGroom", {
    eng: "Search Groom",
    hindi: "वर खोजें",
  });

  map.set("searchResults:", {
    eng: "Search results:",
    hindi: "सर्च रिज़ल्ट्स:",
  });

  map.set("selectAmount", {
    eng: "Select Amount",
    hindi: "राशि सिलेक्ट करें",
  });

  map.set("selectAtLeastOnePerson", {
    eng: "Please select, at least one person, by touching yellow cirle. The selected persons will be saved in the list.",
    hindi:
      "कृपया, पीले-गोल निशान पर टच करके, कुछ लोगों को सेलेक्ट करें, उन्हें ही लिस्ट में सेव किया जाएगा.",
  });
  map.set("selectBirthYear", {
    eng: "Select Birth Year",
    hindi: "जन्म वर्ष चुने",
  });
  map.set("selectGender", {
    eng: "Select Gender",
    hindi: "लिंग चुने",
  });
  map.set("selectKundliDoshas:", {
    eng: "Select Kundli Doshas :",
    hindi: "कुण्डली दोष चुने:",
  });
  map.set("selected:", {
    eng: "Selected: ",
    hindi: "सिलेक्टेड: ",
  });
  map.set("self", {
    eng: "SELF",
    hindi: "स्वयं",
  });
  map.set("sendEmailChangeOtpOnEmail1", {
    eng: "Please send activation email to,",
    hindi: "वेरीफिकेशन के लिए कृपया,",
  });
  map.set("sendEmailChangeOtpOnEmail2", {
    eng: " from your new email ID.",
    hindi: " पर अपने नये ईमेल आई.डी. से एक ईमेल भेजिये.",
  });

  map.set("sendOtpOnEmail1", {
    eng: "Please send activation email,",
    hindi: "वेरीफिकेशन के लिए, कृपया अपने नये ईमेल आई.डी. से,",
  });
  map.set("sendOtpOnEmail2", {
    eng: " from your new email.",
    hindi: " पर एक ईमेल भेजिये.",
  });
  map.set("sendOtpOnEmail3", {
    eng: "Subject of email should be: ",
    hindi: "ईमेल का सब्जेक्ट होना चाहिये : ",
  });
  map.set("sendOtpOnEmail4", {
    eng: "Content of email should be blank.",
    hindi: "ईमेल का कंटेंट/मैसेज खाली रहने दें.",
  });
  map.set("sendOtpOnPhone1", {
    eng: "Please send activation message,",
    hindi: "वेरीफिकेशन के लिए, कृपया अपने नये-रजिस्ट्रेशन फ़ोन से,",
  });
  map.set("sendOtpOnPhone2", {
    eng: " from your new-registration phone,",
    hindi: " पर एक मैसेज भेजिये,",
  });
  map.set("sendOtpOnPhone3", {
    eng: " message must have content as:",
    hindi: " मैसेज में टाइप करें : ",
  });

  map.set("sendPasswordResetOtpOnEmail1", {
    eng: "Please send activation email,",
    hindi: "वेरीफिकेशन के लिए, कृपया अपने रजिस्टर किये हुए ईमेल आई.डी. से,",
  });

  map.set("sendPasswordResetOtpOnEmail2", {
    eng: " from your registered email.",
    hindi: " पर एक ईमेल भेजिये.",
  });
  map.set("sendResetOtpOnPhone1", {
    eng: "Please send verification message,",
    hindi: "वेरीफिकेशन के लिए, कृपया अपने रजिस्टर किये हुए फ़ोन से,",
  });
  map.set("sendResetOtpOnPhone2", {
    eng: " from your registered phone,",
    hindi: " पर एक मैसेज भेजिये,",
  });
  map.set("settings", {
    eng: "Settings",
    hindi: "सेटिंग्स",
  });

  map.set("settings:", {
    eng: "Settings:",
    hindi: "सेटिंग्स:",
  });
  map.set("siteIsNotIntendedForChildren", {
    eng: "Our site is not intended for children under the age of 18. We do not knowingly collect personal information from children under 18.",
    hindi:
      "हमारी साइट 18 वर्ष से कम उम्र के बच्चों के लिए नहीं है। हम जानबूझकर 18 वर्ष से कम उम्र के बच्चों से व्यक्तिगत जानकारी एकत्र नहीं करते हैं।",
  });
  map.set("siteIsProvidedAsIs", {
    eng: "The Site is provided on an as-is and as-available basis, without any warranties of any kind, express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement.",
    hindi:
      "यह साइट जैसी बनायीं गई है वैसी ही आपके लिए प्रस्तुत की गयी है, यह किसी भी प्रकार की वारंटी या गारंटी नहीं देती है|",
  });

  map.set("sorryForThisError", {
    eng: "We are sorry for this error.",
    hindi: "रूकावट के लिए खेद है.",
  });

  map.set("source", {
    eng: "Source",
    hindi: "साधन",
  });

  map.set("specialAbility", {
    eng: "Special Ability",
    hindi: "दिव्यांगता",
  });

  map.set("specialAbilityDetails", {
    eng: "Ability Details",
    hindi: "दिव्यांगता डिटेल्स",
  });
  map.set("specialAbilityLabel", {
    eng: "Special Ability",
    hindi: "दिव्यांगता",
  });

  map.set("speciallyAbled", {
    eng: "Specially Abled",
    hindi: "दिव्यांग",
  });
  map.set("spellingMistake", {
    eng: "Spelling of city name or anything else is incorrect,",
    hindi: "आपके शहर का नाम या स्पेलिंग गलत दिख रही हो,",
  });

  map.set("state", {
    eng: "State",
    hindi: "राज्य",
  });

  map.set("stateUt", {
    eng: "State/UT",
    hindi: "राज्य/प्रदेश",
  });
  map.set("stepsToRegisterUsingEmail", {
    eng: "Steps to register using email.",
    hindi: "ऐप में ईमेल से रजिस्टर करने के स्टेप्स",
  });

  map.set("stepsToRegisterUsingPhone", {
    eng: "Steps to register using phone.",
    hindi: "ऐप में फ़ोन से रजिस्टर करने के स्टेप्स.",
  });
  map.set("stepsToSearchGroomOrBride", {
    eng: "Steps to search groom or bride.",
    hindi: "वर या वधू सर्च करने के स्टेप्स.",
  });
  map.set("stepsToUpdateProfileDetails", {
    eng: "Steps to updat profile details.",
    hindi: "प्रोफाइल डिटेल्स अपडेट करने के स्टेप्स.",
  });

  map.set("submit", {
    eng: "Submit",
    hindi: "आगे बढ़ें",
  });

  map.set("suggestionToSearchAndShortlistGrooms1", {
    eng: "No saved list found.",
    hindi: "आपके लिए कोई लिस्ट नहीं मिली है.",
  });

  map.set("suggestionToSearchAndShortlistGrooms2", {
    eng: "You can do new search and save the shortlisted members in a list to view them later.",
    hindi:
      "आप वर/वधू सर्च करने के बाद नयी लिस्ट बनाकर, उस लिस्ट में मनपसंद लोगों को सेव कर के रख सकते हैं, ताकि आपको फिर से उन्हें सर्च नहीं करना पड़ेगा.",
  });

  map.set("suggestionToSearchAndShortlistGrooms3", {
    eng: "Click on New Search button to do the search.",
    hindi: "कृपया सर्च करने के लिए ऊपर दी गयी नयी सर्च बटन का उपयोग करें.",
  });
  map.set("suspensionOrTerminationOfAcct", {
    eng: "Suspension/Termination of a user's account",
    hindi: "यूज़र अकाउंट का निलंबन/समाप्ति",
  });
  map.set("systemWillBeInMaintennance", {
    eng: "Site will be under maintenance from 10:30 pm to 6:30 am.",
    hindi:
      "मेंटेनेंस की वजह से, साइट की सेवाएँ रात 10:30 से सुबह 6:30 तक डाउन रहेगी.",
  });
  map.set("termsAndConditions", {
    eng: "Terms & Conditions",
    hindi: "नियम एवं शर्ते",
  });
  map.set("termsAndSiteModifications", {
    eng: "Modifications to the site and terms",
    hindi: "साइट और नियमों में संशोधन",
  });
  map.set("termsGovernedByIndianCourts", {
    eng: "These Terms and Conditions shall be governed by and construed in accordance with the laws of India. Any dispute, claim, or controversy arising out of or relating to these Terms and Conditions, shall be subject to the exclusive jurisdiction of the courts located in Dewas District, Madhya Pradesh, India.",
    hindi:
      "ये नियम और शर्तें भारत के कानूनों द्वारा शासित और उनके अनुसार व्याख्यायित होंगी। इन नियमों और शर्तों से उत्पन्न होने वाले या इनसे संबंधित किसी भी विवाद, दावे या मतभेद का निराकरण, भारत के मध्य प्रदेश के देवास जिले में स्थित न्यायालयों के क्षेत्राधिकार के अधीन होगा।",
  });
  map.set("termsUpdatedOn", {
    eng: "Updated on: 01-Dec-2024",
    hindi: "अपडेट की तारीख : 01-Dec-2024",
  });
  map.set("textOnButtonCanBeImproved", {
    eng: "Text on a button or label can be improved,",
    hindi: "कोई बटन या मैसेज का डिटेल सुधारा जा सकता हो,",
  });

  map.set("theProcessMayTakeFewMinutes", {
    eng: "This process may take maximum 30 minutes.",
    hindi: "इस प्रक्रिया में मैक्सिमम 30 मिनट लग सकते हैं.",
  });
  map.set("thenCallUs", {
    eng: "then, for making our society better, please call/email/SMS to us,",
    hindi:
      "तो अपने समाज के हित के लिए, कृपया हमें ईमेल/SMS या फ़ोन कर के ज़रूर बतायें.",
  });
  map.set("thereIsSeparateTermsDoc", {
    eng: "There is a separate link to view all our terms and conditions that you must read carefully and agree before using this site.",
    hindi:
      "हमारे सभी नियमों और शर्तों को देखने के लिए एक अलग लिंक है जिसे आपको इस साइट का उपयोग करने से पहले ध्यान से पढ़ना और सहमत होना चाहिए।",
  });
  map.set("theseTermsGovernsYourUse", {
    eng: "These Terms and Conditions govern your use of this site or webapp. This platform is designed to facilitate connections between individuals of Yadav Ahir Community, seeking marriage partners for them and their family members or who just want to connect with other members of the same community. By accessing or using the site, you agree to be bound by these terms and conditions. All of the terms an conditions are very necessary to be agreed and followed for the proper working and usage of this site for all the users. If you do not agree to any of these terms and conditions, please do not use this site.",
    hindi:
      "इस साइट या वेब ऐप का उपयोग करते समय यहाँ दी गयी नियम एवं शर्तों का पूर्ण रूप से पालन करना अनिवार्य है| यह ऑनलाइन सेवा यादव अहीर समाज के लगभग हर उस व्यक्ति के लिए उपयोगी है जो खुद के या अपने फॅमिली मेंबर्स के लिये उचित एवं योग्य वर या वधू खोज रहे हैं या जो अपने समाज के अन्य लोगों से जुड़ना चाहते हैं| अगर आप इस साइट का यूज़ करते हैं तो इसका अर्थ यह है कि आपको यहाँ पर दिये गये, साइट के उपयोग से सम्बंधित सारे नियम एवं शर्ते स्वीकार हैं| यहाँ दिये गये सारे नियम एवं शर्तें इस साइट को सभी यूज़र्स के लिये ठीक तरह से चलाने के लिए अत्यंत ज़रूरी हैं| यदि आपको एक भी नियम या शर्त स्वीकार नहीं है तो कृपया इस साइट का उपयोग ना करें|",
  });
  map.set("thirdPartyLinks", {
    eng: "Third-Party Links",
    hindi: "थर्ड पार्टी लिंक्स",
  });
  map.set("thisListIsDeleted", {
    eng: "This list is deleted.",
    hindi: "यह लिस्ट डिलीट हो चुकी है.",
  });
  map.set("tillYourAccountIsActive", {
    eng: "As long as you keep your account active we keep your information stored in our database servers. As soon as you update or delete any information from your or family member's profile, that information is overwritten on our servers, so we don't store old information of yours. We also don't store any old photo or media uploaded by you once you update or delete them.",
    hindi:
      "जब तक आप अपना अकाउंट सक्रिय रखते हैं, हम आपकी जानकारी को हमारे डेटाबेस सर्वर में संग्रहीत रखते हैं। जैसे ही आप अपने या परिवार के सदस्य की प्रोफ़ाइल से कोई जानकारी अपडेट करते या हटाते हैं, वह जानकारी हमारे सर्वर पर भी अपडेट हो जाती है, इसलिए हम आपकी पुरानी जानकारी संग्रहीत नहीं करते हैं। जब आप फोटो या मीडिया को अपडेट करते हैं तो हम आपके द्वारा अपलोड की गई कोई भी पुरानी फ़ोटो या मीडिया भी संग्रहीत नहीं करते हैं।",
  });
  map.set("timestamp", {
    eng: "Timestamp",
    hindi: "टाइमस्टांप",
  });

  map.set("to", {
    eng: "To",
    hindi: "से",
  });
  map.set("toAnalyzeUsagePatterns", {
    eng: "To analyze usage patterns and improve the functionality and design of the site.",
    hindi:
      "साइट के उपयोग के तरीकों का विश्लेषण करने और इसकी कार्यक्षमता और डिज़ाइन को बेहतर बनाने के लिए।",
  });
  map.set("toComplyWithLaws", {
    eng: "To comply with applicable laws and regulations.",
    hindi: "लागू होने वाले कानूनों और नियमों का पालन करने के लिए।",
  });
  map.set("toContact", {
    eng: "To contact:",
    hindi: "संपर्क करने के लिये:",
  });
  map.set("toDetectAndPreventFraud", {
    eng: "To detect and prevent fraudulent activity and abuse of the site.",
    hindi:
      "साइट का दुरुपयोग और धोखाधड़ी वाली गतिविधि का पता लगाने और रोकने के लिए।",
  });
  map.set("toFindPeopleBasedOnSearchFilters", {
    eng: "To help you find and connect with other users who may be compatible to your search query, depending on the search filters you choose.",
    hindi:
      "आपकी सर्च क्वेरी के अनुकूल अन्य यूज़र्स एवं मेंबर्स को खोजने और उनसे जुड़ने में आपकी सहायता करने के लिए, आपके द्वारा चुने गए सर्च फ़िल्टर के आधार पर।",
  });
  map.set("toOperateSiteCreateUserAcct", {
    eng: "To operate the site, create and manage user accounts, and provide customer support.",
    hindi:
      "साइट को संचालित करने, उपयोगकर्ता के लिए अकाउंट बनाने/मैनेज करने और ग्राहक सहायता प्रदान करने के लिए।",
  });

  map.set("toReachUs", {
    eng: "To send message to us:",
    hindi: "हमारे संपर्क सूत्र:",
  });
  map.set("toSendYouImportantNotices", {
    eng: "To send you important notices, updates, and promotional materials related to the site.",
    hindi:
      "साइट से संबंधित महत्वपूर्ण सूचनाएँ, अपडेट और प्रचार सामग्री आपको भेजने के लिए।",
  });

  map.set("topUpWalletFromProfile", {
    eng: "Your wallet balance is low, please add balance from Profile section.",
    hindi: "आपका वॉलेट बैलेंस कम है, कृपया प्रोफाइल में जाकर रिचार्ज करें.",
  });
  map.set("total", {
    eng: "Total",
    hindi: "टोटल",
  });

  map.set("touchBelowButtonToGoBack", {
    eng: "Please click below button to go back.",
    hindi: "कृपया नीचे दिए गए बटन को टच करें.",
  });

  map.set("transactionHistory:", {
    eng: "Transaction history:",
    hindi: "ट्रांज़ेक्शन हिस्ट्री:",
  });
  map.set("typeNewEmail", {
    eng: "Type new email",
    hindi: "नया ईमेल टाइप करें",
  });
  map.set("typeNewPassword", {
    eng: "Type new password.",
    hindi: "नया पासवर्ड टाइप करें",
  });
  map.set("typeNewPhone", {
    eng: "Type new phone no.",
    hindi: "नया फ़ोन नंबर टाइप करें",
  });

  map.set("unblock", {
    eng: "UNBLOCK",
    hindi: "अनब्लॉक",
  });

  map.set("unknown_error", {
    eng: "We are sorry for this error, please refresh and retry.",
    hindi: "रूकावट के लिए खेद है, कृपया पेज को रिफ्रेश कर पुनः प्रयास करें.",
  });

  map.set("upToYears", {
    eng: "Years",
    hindi: "वर्ष तक",
  });
  map.set("updationRemovalOfInfo", {
    eng: "Updation/Removal of information",
    hindi: "जानकारी को  हटाना या अपडेट करना",
  });
  map.set("usageByUnderaged", {
    eng: "Use of site by underaged",
    hindi: "साइट का अल्प वयस्कों द्वारा उपयोग",
  });
  map.set("use", {
    eng: "Use",
    hindi: "उपयोग करें",
  });

  map.set("useAddButtonForFriends", {
    eng: "Use Add button to send new friend requests.",
    hindi: "नयी फ्रेंड रिक्वेस्ट सेंड करने के लिए ऐड बटन का यूज़ करें.",
  });

  map.set("useAddMemberButton", {
    eng: "Please use add member button from top right side to save details about your family members.",
    hindi:
      "कृपया ऊपर दिए गए ऐड मेंबर बटन पर टच कर के अपने फ़ैमिली मेंबर्स की जानकारी सेव कीजिये.",
  });
  map.set("useAutomatedMeansToCopySiteItems", {
    eng: "Use any automated means, including scripts, robots, crawlers, or scrapers, to access, use, or copy any portion of the Site, except as expressly authorized by us in writing.",
    hindi:
      "बिना लिखित अनुमति के, साइट से जानकारी कॉपी करने के लिए किसी ऑटोमेशन टूल, स्क्रिप्ट, रोबोट, स्क्रैपर का उपयोग करना|",
  });
  map.set("useDifferentOtp", {
    eng: "Please use a different OTP.",
    hindi: "कृपया कोई अन्य OTP नंबर उपयोग करें.",
  });
  map.set("useSiteToDisruptNetworks", {
    eng: "Use the Site to engage in any activity that interferes with or disrupts the Site or servers or networks connected to the Site, or disobey any requirements, procedures, policies, or regulations of networks connected to the Site.",
    hindi:
      "साइट का उपयोग किसी ऐसी गतिविधि में करना जो साइट से जुड़े किसी नेटवर्क कंप्यूटर/सर्वर या किसी भी अन्य इलेक्ट्रॉनिक उपकरण या अन्य सॉफ्टवेयर/ऐप या साइट की नियम एवं शर्तो का उल्लंघन करे|",
  });
  map.set("useSiteToTransmitCopyrightedInfo", {
    eng: "Use the Site to transmit any material that infringes any intellectual property right, including but not limited to copyright, patent, trademark, or trade secret.",
    hindi:
      "साइट का उपयोग बौद्धिक सम्पदा अधिकारों का उल्लंघन करने वाली किसी भी चीज़ के प्रसारण में करना, जैसे कि, कॉपीराइट, पेटेंट, ट्रेडमार्क या ट्रैड सीक्रेट।",
  });
  map.set("useSiteToTransmitUnlafulMaterial", {
    eng: "Use the Site to transmit any material that is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, libelous, invasive of another's privacy, hateful, or racially, ethnically, or otherwise objectionable.",
    hindi:
      "साइट का उपयोग कर किसी भी प्रकार की अपमानजनक, नग्नता दर्शाने वाली, आपत्तिजनक, घृणित, नुकसानदायक, धमकी से भरी, अवैधानिक, किसी की प्राइवेसी को नुकसान पहुंचाने वाली जानकारी को प्रसारित करना|",
  });
  map.set("useSiteToTransmitVirus", {
    eng: "Use the Site to transmit any material that contains software viruses or any other computer code, files, or programs designed to interrupt, destroy, or limit the functionality of any computer software or hardware or telecommunications equipment.",
    hindi:
      "साइट का उपयोग किसी भी प्रकार के सॉफ्टवेयर वायरस, फ़ाइल, या ऐसी ऐप या प्रोग्राम के प्रसारण में करना जो किसी कंप्यूटर/मोबाइल फ़ोन/टेबलेट या किसी अन्य इलेक्ट्रॉनिक उपकरण के कार्य को बाधित करे|",
  });
  map.set("userConduct", {
    eng: "User Conduct",
    hindi: "यूज़र का व्यवहार",
  });
  map.set("userIsAlreadyRegistered", {
    eng: "User is already registered for given details.",
    hindi: "इस ईमेल /फोन से पहले ही रजिस्ट्रेशन हो चुका है.",
  });

  map.set("userProfile:", {
    eng: "User Profile:",
    hindi: "यूज़र प्रोफ़ाइल:",
  });
  map.set("usingEmail", {
    eng: "Using email",
    hindi: "ईमेल द्वारा",
  });
  map.set("usingSms", {
    eng: "Using SMS",
    hindi: "SMS द्वारा",
  });
  map.set("verification", {
    eng: "Verification",
    hindi: "वेरिफिकेशन",
  });
  map.set("verificationSteps", {
    eng: "Verification Steps:",
    hindi: "वेरिफ़िकेशन स्टेप्स:",
  });

  map.set("verify", {
    eng: "Verify",
    hindi: "वेरीफाई",
  });

  map.set("viewTransactions", {
    eng: "View transactions",
    hindi: "ट्रांजेक्शंस देखें",
  });
  map.set("waiting", {
    eng: "Waiting",
    hindi: "वेटिंग",
  });

  map.set("walletDetails", {
    eng: "Wallet Details",
    hindi: "वॉलेट डिटेल्स",
  });
  map.set("warrantyDisclaimer", {
    eng: "Disclaimer of Warranties",
    hindi: "वारंटी का अस्वीकरण",
  });
  map.set("watchHelpVideosForMoreInfo", {
    eng: "For more details, please Watch videos from Help section.",
    hindi: "अधिक जानकारी के लिये हेल्प सेक्शन में जाकर वीडियो देखें.",
  });
  map.set("weAreNeverLiableForAnyDamage", {
    eng: "In no event shall we be liable for any damages whatsoever, including but not limited to direct, indirect, incidental, special, consequential, or punitive damages, arising out of or in any way connected with the use of the Site, whether in contract, tort, strict liability, or otherwise, even if we have been advised of the possibility of such damages.",
    hindi:
      "किसी भी स्थिति में, हम किसी भी प्रकार के नुकसान के लिए उत्तरदायी नहीं होंगे, जिसमें प्रत्यक्ष, अप्रत्यक्ष, आकस्मिक, विशेष, परिणामी, या दंडात्मक नुकसान शामिल हैं, लेकिन इन्हीं तक सीमित नहीं हैं, जो साइट के उपयोग से उत्पन्न होते हैं या किसी भी तरह से जुड़े होते हैं, चाहे अनुबंध, अपकृत्य, कठोर दायित्व, या अन्यथा में, भले ही हमें ऐसे नुकसान की संभावना के बारे में सलाह दी गई हो।",
  });
  map.set("weAreNotResponsibleFor3rdPartyPolicies", {
    eng: "The Site may contain links to third-party websites. We are not responsible for the content or privacy practices of any third-party websites.",
    hindi:
      "साइट में थर्ड पार्टी वेबसाइट्स के लिंक हो सकते हैं। हम किसी भी थर्ड पार्टी वेबसाइट की सामग्री या गोपनीयता पॉलिसीज के लिए ज़िम्मेदार नहीं हैं।",
  });
  map.set("weAreNotResponsibleForOthersInfo", {
    eng: "You understand and acknowledge that we cannot assume any responsibility for the content of messages sent by other users of the Website, and you release us from any and all liability in connection with the contents of any communications you may receive from other users.",
    hindi:
      "आप समझते हैं और स्वीकार करते हैं कि हम वेबसाइट के अन्य यूज़र्स द्वारा भेजी गयी जानकारी के लिए कोई जिम्मेदारी नहीं ले सकते हैं, और आप अन्य यूज़र्स से प्राप्त होने वाली किसी भी जानकारी के संबंध में हमें सभी प्रकार के दायित्व से मुक्त करते हैं।",
  });
  map.set("weCanSuspendAcctAnytime", {
    eng: "We may, at our sole discretion, terminate or suspend your access to all or part of the Website at any time, with or without notice, for any reason, including but not limited to a breach of this Agreement. Fraudulent, abusive, or illegal activity may result in immediate termination of your access.",
    hindi:
      "हम, अपने निर्णय के आधार पर, किसी भी समय, बिना सूचना के या सूचना के साथ, किसी भी कारण से, जिसमें इन नियम एवं शर्तो का का उल्लंघन भी शामिल है, लेकिन इन्हीं तक सीमित नहीं है, वेबसाइट के पूरे या आंशिक भाग तक आपकी एक्सेस को समाप्त या निलंबित कर सकते हैं। धोखाधड़ी, अपमानजनक या अवैध गतिविधि के परिणामस्वरूप आपकी एक्सेस तत्काल समाप्त की जा सकती है।",
  });
  map.set("weCanSuspendYourAccountBecauseOf:", {
    eng: "We reserve the right to suspend or terminate your account at any time, without prior notice, for any reason, including but not limited to:",
    hindi:
      "हम ये अधिकार सुरक्षित रखते हैं की हम आपके अकाउंट को, कभी भी, बिना किसी नोटिस दिये, सस्पेंड या बर्खास्त कर सकते हैं, ऐसा करने के लिए हमें बाध्य करने वाले कुछ कारण नीचे दिये गये हैं:",
  });
  map.set("weCanUpdateSiteAnytime", {
    eng: "We reserve the right to modify these Terms and Conditions at any time without prior notice. Also, we can update the site to fix any bugs or to release new features as and when we want.",
    hindi:
      "हमारे पास बिना पूर्व सूचना के किसी भी समय इन नियमों और शर्तों को संशोधित करने का अधिकार सुरक्षित है। साथ ही, हम किसी भी बग/त्रुटि को ठीक करने या नई सुविधाओं को जारी करने के लिए जब चाहें तब साइट को अपडेट कर सकते हैं।",
  });
  map.set("weCantGuaranteeAccuracy", {
    eng: "You understand and acknowledge that we cannot guarantee, and assume no responsibility for verifying, the accuracy of the information provided by other users of the Website. We just present the information as it is provided by the corresponding user to us. Also, you must validate the accuracy of information using your best judgement and outside or personal research or enquiries.",
    hindi:
      "आप समझते हैं और स्वीकार करते हैं कि हम वेबसाइट के अन्य यूज़र्स द्वारा प्रदान की गई जानकारी की सटीकता की गारंटी नहीं दे सकते हैं, और न ही इसे सत्यापित करने की कोई जिम्मेदारी लेते हैं। हम जानकारी को वैसे ही प्रस्तुत करते हैं जैसा इसे संबंधित यूज़र्स द्वारा हमें प्रदान किया जाता है। साथ ही, आपको अपनी समझ और बाहरी या व्यक्तिगत पूछताछ का उपयोग करके जानकारी की सटीकता को सुनिश्चित करना होगा।",
  });
  map.set("weCollectCustomerSupportInquiryDetails", {
    eng: "When you contact customer support, we collect information related to your inquiry.",
    hindi:
      "जब आप ग्राहक सहायता से संपर्क करते हैं, तो हम आपकी पूछताछ से संबंधित जानकारी एकत्र करते हैं।",
  });
  map.set("weCollectInfoAboutConnection", {
    eng: "We collect information about your communication/connection with other users through the site.",
    hindi:
      "हम साइट के माध्यम से अन्य उपयोगकर्ताओं के साथ आपके संचार/संपर्क के बारे में जानकारी एकत्र करते हैं।",
  });
  map.set("weCollectLogsEtc", {
    eng: "We automatically collect log information, including your IP address, browser type, operating system, access times, and pages viewed. We may collect information about the device you use to access the site, including the device model, operating system, and unique device identifiers.",
    hindi:
      "हम स्वचालित रूप से लॉग जानकारी एकत्र करते हैं, जिसमें आपका आईपी एड्रेस, ब्राउज़र प्रकार, ऑपरेटिंग सिस्टम, एक्सेस समय और देखे गए पृष्ठ शामिल हैं। हम आपके द्वारा साइट तक पहुँचने के लिए उपयोग किए जाने वाले डिवाइस के बारे में जानकारी एकत्र कर सकते हैं, जिसमें डिवाइस मॉडल, ऑपरेटिंग सिस्टम और यूनिक डिवाइस आइडेंटिफायर शामिल हैं।",
  });
  map.set("weCollectPaymentRelatedInfo", {
    eng: "If you subscribe to any paid services on the site, we collect payment information necessary to process your transactions. We also record the transaction to keep track of the wallet/credit points spent.",
    hindi:
      "यदि आप साइट पर किसी भी सशुल्क सेवा की सदस्यता लेते हैं, तो हम आपके लेनदेन को संसाधित करने के लिए आवश्यक भुगतान जानकारी एकत्र करते हैं। हम खर्च किए गए वॉलेट/क्रेडिट पॉइंट्स का ट्रैक रखने के लिए लेनदेन को भी रिकॉर्ड करते हैं।",
  });
  map.set("weDontReviewEachDetail", {
    eng: "We don't review every message or user-generated content on the site and aren't responsible for it. However, we reserve the right (but not the obligation) to remove, relocate, or modify any content that we, in our sole judgment, find violates our Code of Conduct, content guidelines, or is otherwise inappropriate.",
    hindi:
      "हम साइट पर उपलब्ध हर एक जानकारी की समीक्षा नहीं करते हैं और इसके लिए ज़िम्मेदार नहीं हैं। हालाँकि, हम अपने निर्णय में, ऐसी किसी भी सामग्री को हटाने, स्थानांतरित करने या संशोधित करने का अधिकार (लेकिन दायित्व नहीं) सुरक्षित रखते हैं जो हमारे नियम एवं शर्तो का उल्लंघन करती है, या अन्यथा अनुचित है।",
  });
  map.set("weMayShareYourInfoWith3rdParty", {
    eng: "We may share your information with third-party service providers who assist us with operating the site, such as payment processors, hosting providers, and analytics providers.",
    hindi:
      "हम आपकी जानकारी को तृतीय-पक्ष सेवा प्रदाताओं के साथ साझा कर सकते हैं जो साइट को संचालित करने में हमारी सहायता करते हैं, जैसे कि भुगतान प्रोसेसर, होस्टिंग प्रदाता और विश्लेषिकी प्रदाता।",
  });
  map.set("weMayShareYourInfoWithLegalAuthorities", {
    eng: "We may disclose your information to legal authorities if required by law or legal process.",
    hindi:
      "कानून या कानूनी प्रक्रिया द्वारा आवश्यक होने पर हम आपकी जानकारी कानूनी अधिकारियों को दे सकते हैं।",
  });
  map.set("weMayUpdatePrivacyPolicy", {
    eng: "We may update this Privacy Policy from time to time. We will post any changes on this page and encourage you to review it periodically.",
    hindi:
      "हम समय-समय पर इस गोपनीयता नीति को अपडेट कर सकते हैं। हम इस पृष्ठ पर कोई भी बदलाव पोस्ट करेंगे और आपको इसे समय-समय पर समीक्षा करने के लिए प्रोत्साहित करते हैं।",
  });
  map.set("weStoreBackups", {
    eng: "We do store backup of old data snapshot in encrypted format. This backup we use to restore previous information in case of any unrecoverable technical error or to resolve payment related errors. Such backups are only kept up to 100 days.",
    hindi:
      "हम एन्क्रिप्टेड फॉर्मेट में पुराने डेटा स्नैपशॉट का बैकअप ज़रूर रखते हैं। इस बैकअप का उपयोग हम किसी भी अपूरणीय तकनीकी त्रुटि की स्थिति में या भुगतान संबंधी त्रुटियों को हल करने के लिए पिछली जानकारी को पुनर्स्थापित करने के लिए करते हैं। ऐसे बैकअप केवल पिछले 100 दिनों की जानकारियों के ही रखे जाते हैं।",
  });
  map.set("weTakeReasonableMeasures", {
    eng: "We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the Internet or electronic storage is completely secure.",
    hindi:
      "हम आपकी जानकारी को अनधिकृत पहुँच, उपयोग या प्रकटीकरण से बचाने के लिए उचित उपाय करते हैं। हालाँकि, इंटरनेट या इलेक्ट्रॉनिक स्टोरेज पर ट्रांसमिशन का कोई भी तरीका पूरी तरह से सुरक्षित नहीं होता है।",
  });
  map.set("weUseBrowserLocalStorage", {
    eng: "We use browser's local storage option to save information to run the app smoothly on your device. That information is cleared when a user logs out.",
    hindi:
      "हम आपके डिवाइस पर ऐप को सुचारू रूप से चलाने के लिए जानकारी सहेजने के लिए ब्राउज़र के लोकल स्टोरेज विकल्प का उपयोग करते हैं। जब कोई उपयोगकर्ता लॉग आउट करता है तो वह जानकारी हटा दी जाती है।",
  });

  map.set("weight", {
    eng: "Weight",
    hindi: "वज़न",
  });

  map.set("weightRange", {
    eng: "Weight Range",
    hindi: "वज़न रेंज",
  });

  map.set("welcomeTitleLine1", {
    eng: "Welcome to this portal",
    hindi: "यादव अहीर समाज के इस मंच पर",
  });

  map.set("welcomeTitleLine2", {
    eng: "For Yadav Ahir Community",
    hindi: "आपका हार्दिक स्वागत है",
  });
  map.set("yearlyIncome", {
    eng: "Yearly Income",
    hindi: "सालाना इनकम",
  });
  map.set("youAckToUseAntivirus", {
    eng: "You acknowledge that you are solely responsible for any damage to your computer system or loss of data that results from the use of the Site. We recommend to user proper security mechanism, such as antivirus etc. for the safety of your devices.",
    hindi:
      "आप स्वीकार करते हैं कि आप अपने फ़ोन/कंप्यूटर या किसी भी अन्य डिवाइस को साइट के माध्यम से अनचाहे रूप से होने वाले किसी भी नुकसान से सुरक्षा के लिए स्वयं ज़िम्मेदार हैं| हम आपको अपनी डिवाइस की सुरक्षा के लिए उचित एंटीवायरस सॉफ्टवेयर उपयोग करने का सुझाव देते हैं|",
  });
  map.set("youAgreeThatAlwaysSecurityIsNotPossible", {
    eng: "You understand and acknowledge that although we are using all the recommended protection methods to protect your data, but it is not possible to always ensure the security or privacy of information you provide through the Internet and your email messages, and you release us from any and all liability in connection with the use of such information by other parties.",
    hindi:
      "आप समझते हैं और स्वीकार करते हैं कि यद्यपि हम आपके डेटा को सुरक्षित रखने के लिए सभी अनुशंसित सुरक्षा विधियों का उपयोग कर रहे हैं, लेकिन आपके द्वारा प्रदान की गई जानकारी की सुरक्षा या गोपनीयता को हमेशा सुनिश्चित करना संभव नहीं है, और आप अन्य पक्षों द्वारा ऐसी जानकारी के उपयोग के संबंध में हमें सभी दायित्व से मुक्त करते हैं।",
  });
  map.set("youAgreeThatYouWillNever:", {
    eng: "You agree that you will never:",
    hindi:
      "आप सहमत है की आप कभी भी साइट के माध्यम से नीचे दिये गये कार्यो में से कोई भी या मिलता जुलता किसी भी प्रकार का कार्य नहीं करेंगे:",
  });
  map.set("youAgreeToIndemnifyUs", {
    eng: "You agree to indemnify and hold us harmless from and against any and all claims, liabilities, damages, losses, costs, and expenses, including attorneys' fees, arising out of or in any way connected with your use of the Site or your violation of these Terms and Conditions.",
    hindi:
      "आप हमारी क्षतिपूर्ति करने और हमें किसी भी और सभी दावों, देनदारियों, नुकसानों, हानियों, लागतों और खर्चों, जिनमें वकीलों की फीस भी शामिल है, से हानिरहित रखने के लिए सहमत हैं, जो आपके द्वारा साइट के उपयोग या इन नियमों और शर्तों के आपके उल्लंघन से उत्पन्न होते हैं या किसी भी तरह से जुड़े होते हैं।",
  });
  map.set("youCanChangeLanguage", {
    eng: "You can change language between English and Hindi from menu settings. We keep relevant translation of words in these languages. Some words are kept untranslated.",
    hindi:
      "आप मेनू सेटिंग्स से अंग्रेजी और हिंदी के बीच भाषा बदल सकते हैं। हम इन भाषाओं में शब्दों का प्रासंगिक अनुवाद रखते हैं। कुछ शब्दों का अनुवाद नहीं किया जाता है।",
  });
  map.set("youCanUpdateYourProfile", {
    eng: "You can access and update your profile or members' information at any time through the options available in the site.",
    hindi:
      "आप साइट में उपलब्ध विकल्पों के माध्यम से किसी भी समय अपनी प्रोफ़ाइल या सदस्यों की जानकारी को एक्सेस और अपडेट कर सकते हैं।",
  });
  map.set("youCannotSueThisSite", {
    eng: "You understand and acknowledge that you cannot sue this site or any of its employees, owner or any person related to this site for any damages done to you through the site.",
    hindi:
      "आप समझते हैं और स्वीकार करते हैं कि आप इस साइट या इसके किसी कर्मचारी, स्वामी या इस साइट से संबंधित किसी भी व्यक्ति पर साइट के माध्यम से आपको हुए किसी भी नुकसान के लिए किसी भी प्रकार का कोई मुकदमा नहीं कर सकते हैं।",
  });
  map.set("youCantUseThisSitesInfo", {
    eng: "You may not use any of the Site's content for any commercial or other purpose without our prior written consent.",
    hindi:
      "हमारी पूर्व लिखित सहमति के बिना आप साइट की किसी भी सामग्री का उपयोग किसी भी व्यावसायिक या अन्य उद्देश्य के लिए नहीं कर सकते हैं।",
  });
  map.set("youCauseAnyProblem", {
    eng: "You cause any action which creates any type of problem in the site's functioning.",
    hindi:
      "आप कोई भी ऐसा काम करते हैं जिस से साइट के संचालन में कोई रुकावट या परेशानी आये|",
  });
  map.set("youEngageInIllegalActivity", {
    eng: "You engage in any activity that is illegal, harmful, or disruptive to the site or other users.",
    hindi:
      "आप कोई ऐसी गतिविधि करते हैं जो गैर क़ानूनी हो और साइट या अन्य यूज़र्स को किसी भी प्रकार का कोई नुकसान पहुंचाये|",
  });
  map.set("youHaveAccountDetails?", {
    eng: "You remember account details?",
    hindi: "आपको लॉगिन डिटेल्स याद हैं?",
  });
  map.set("youKnowAllUsersWillAccessYourInfo", {
    eng: "You know and understand the risk that your information will be accessed by any registered user. The owner of this web app will not be responsible for any kind of misuse of the information. The information you provide e.g. phone, email, address and other text and images etc., is shared with all other registered users when they search for people for friendship and for brides and grooms.",
    hindi:
      "आप जानते और समझते हैं कि आपकी जानकारी किसी भी रजिस्टर्ड यूजर द्वारा एक्सेस की जा सकती है। इस वेब ऐप से सम्बंधित कोई भी अधिकारी या स्वामी, किसी अन्य यूज़र्स द्वारा जानकारी के किसी भी प्रकार के दुरुपयोग के लिए ज़िम्मेदार नहीं होगा। आपके द्वारा प्रदान की गई जानकारी, जैसे कि फ़ोन, ईमेल, पता और प्रोफाइल एवं मेंबर्स की इमेज आदि, अन्य सभी रजिस्टर्ड यूज़र्स के साथ साझा की जाती है जब वे मित्रता और वर-वधू के लिए लोगों की तलाश करते हैं।",
  });
  map.set("youMayChooseToProvideExtraInfo", {
    eng: "You may choose to provide additional information for your profile, such as photos, height, weight, special ability details, descriptions of yourself, your food preferences, your source of income and your education details. We also collect your state, city and detail address.",
    hindi:
      "आप अपनी प्रोफ़ाइल के लिए अतिरिक्त जानकारी प्रदान करना चुन सकते हैं, जैसे कि फ़ोटो, ऊँचाई, वज़न, दिव्यांगता विवरण, अपने बारे में विवरण, आपकी भोजन प्राथमिकताएँ, आपकी आय का स्रोत और आपकी शिक्षा का विवरण। हम आपका राज्य, शहर और विस्तृत पता भी एकत्र करते हैं।",
  });

  map.set("youMustMaintainConfidentiality", {
    eng: "You are responsible for maintaining the confidentiality of your account login information, including your password.",
    hindi:
      "अपने अकाउंट में लॉगिन करने के लिए पासवर्ड इत्यादि जानकारी की गोपनीयता को कायम रखना स्वयं आपकी ज़िम्मेदारी है|",
  });
  map.set("youShouldBeSureAboutInfoYouAreSharing", {
    eng: "You understand and acknowledge that we are not responsible for, and cannot control, the use by others of any information which you provide to them and you should use caution in selecting the personal information you provide to others through the Website.",
    hindi:
      "आप समझते हैं और स्वीकार करते हैं कि हम आपके द्वारा उन्हें प्रदान की गई किसी भी जानकारी के दूसरों द्वारा उपयोग के लिए ज़िम्मेदार नहीं हैं, और इसे नियंत्रित नहीं कर सकते हैं, और आपको वेबसाइट के माध्यम से दूसरों को प्रदान की जाने वाली व्यक्तिगत जानकारी का चयन करने में सावधानी बरतनी चाहिए।",
  });
  map.set("youUseMeansYouKnowLatestTerms", {
    eng: "Your continued use of the Site following the posting of any changes to these Terms and Conditions constitutes your acceptance of such changes.",
    hindi:
      "इन नियमों और शर्तों में किसी भी बदलाव के पब्लिश होने के बाद आपके द्वारा साइट का निरंतर उपयोग ऐसे बदलावों के प्रति आपकी स्वीकृति का होना व्यक्त करता है।",
  });
  map.set("youWillUseTheSiteForLawfulPurposes", {
    eng: "You agree to use the Site only for lawful purposes and in a manner that does not infringe upon the rights of, or restrict or inhibit the use and enjoyment of, the Site by any third party.",
    hindi:
      "आप सहमत हैं कि आप इस साइट का उपयोग हमेशा वैधानिक तरीके से करेंगे और इस साइट के माध्यम से किसी भी अन्य व्यक्ति/समुदाय या संस्था का कोई नुकसान करने का प्रयास नहीं करेंगे|",
  });
  map.set("yourConsent", {
    eng: "Your Consent",
    hindi: "आपकी सहमति",
  });

  map.set("yourEmailChangeRequestIsAccepted", {
    eng: "Your email change request is accepted.",
    hindi: "आपकी ईमेल चेंज रिक्वेस्ट स्वीकार कर ली गयी है.",
  });
  map.set("yourEmailIs", {
    eng: "Your email is: ",
    hindi: "आपका ईमेल आई. डी. है: ",
  });
  map.set("yourInformationOptions", {
    eng: "Your options about information",
    hindi: "जानकारियों से सम्बंधित आपके विकल्प",
  });
  map.set("yourNewEmailIs", {
    eng: "Your new email ID is: ",
    hindi: "आपका नया ईमेल आई. डी. है: ",
  });
  map.set("yourNewPhoneIs", {
    eng: "Your new phone no. is : ",
    hindi: "आपका नया फ़ोन नंबर है :",
  });

  map.set("yourPhoneChangeRequestIsAccepted", {
    eng: "Your phone change request is accepted.",
    hindi: "आपकी फ़ोन चेंज रिक्वेस्ट स्वीकार कर ली गयी है.",
  });
  map.set("yourPhoneIs", {
    eng: "Your phone is : ",
    hindi: "आपका फ़ोन नंबर है :",
  });
  map.set("yourProfileIsSharedWithOthers", {
    eng: "Your profile and family members' information and except the password, will be visible to all the other users of the site.",
    hindi:
      "पासवर्ड के अलावा आपकी प्रोफ़ाइल एवं आपके फैमिली मेंबर्स की जानकारी, साइट के अन्य सभी उपयोगकर्ताओं को दिखाई देगी।",
  });
  map.set("zzz", {
    eng: "zzz",
    hindi: "zzz",
  });
  //map ends here

  console.log(
    "language map init done.xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
  );
}

export function textFor(key) {
  let langObj = {
    eng: ".",
    hindi: ".",
  };
  if (map.keys().toArray().length < 1) {
    initLanguageMap();
  }
  if (map.has(key)) {
    langObj = map.get(key);
  }
  const currentLanguage = localStorage.getItem(LANGUAGE_KEY);
  if (currentLanguage) {
    switch (currentLanguage) {
      case "HINDI":
        return langObj.hindi;
      case "ENGLISH":
        return langObj.eng;
      default:
        return langObj.eng;
    }
  }
  return langObj.eng;
}
//SAVE CANCEL OK will not be translated
