import AlertDialog from "../../components/UI/alertDialog/AlertDialog";
import { getRelationName } from "../../components/UI/PersonDetailsDialog/PersonDetailsDialog";
import { textFor } from "../../util/languages";
import commonStyles from "../../commonStyles.module.scss";
import { useEffect, useState } from "react";
import { Loader } from "../../components/UI/ErrorModal";
import { BASE_URL } from "../../Constants";
import { doLogout, getAuthToken } from "../../util/auth";
import { authActions } from "../../store/reducers/authSlice";
import { useDispatch } from "react-redux";
import { json } from "react-router-dom";
import { fetchContactSuccess } from "../../store/reducers/contactDetailsSlice";
import { useSelector } from "react-redux";

function PersonContactDetails({ onClose, personDetails }) {
  const [contactDetails, setContactDetails] = useState({});
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const records = useSelector((state) => state.contactDetails.records);

  useEffect(() => {
    const contactFromRedux = records.find(
      (r) => r.memberId === personDetails.id
    );
    if (contactFromRedux) {
      setContactDetails(contactFromRedux);
    } else {
      fetchContactDetails(personDetails.id);
    }
  }, []);
  function getContactDetailsView() {
    function getViewToShowEmailDetailsIfAvailable(personDetails) {
      function getEmailIcon(email) {
        return (
          <span
            className={`${commonStyles.circular} ${commonStyles.greenBackground}`}
          >
            <a
              href={"mailto:" + email}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <i
                style={{ color: "white" }}
                className="fa-2x fa-regular fa-envelope"
              ></i>
            </a>
          </span>
        );
      }
      if (Object.keys(contactDetails).includes("email")) {
        return (
          <>
            <div className={commonStyles.boxWithBorder}>
              <div style={{ flexGrow: "1" }}>
                <div>
                  <span>
                    <b>{textFor("email") + ": "}</b>
                    {contactDetails.email}
                  </span>
                </div>
                <div className={commonStyles.blankHeight2vh}></div>
                <div className={commonStyles.containerForEvenlySpacedChildren}>
                  {getEmailIcon(contactDetails.email)}
                </div>
                <div className={commonStyles.blankHeight2vh}></div>
              </div>
            </div>
          </>
        );
      }
    }
    function getViewToShowPhoneDetailsIfAvailable(personDetails) {
      let phone = "";
      function getPhoneCallIcon(phone) {
        return (
          <span
            className={`${commonStyles.circular} ${commonStyles.grayBackground}`}
          >
            <a href={"tel:" + phone} target="_blank" rel="noopener noreferrer">
              <i
                style={{ color: "green" }}
                className="fa-2x fa-solid fa-phone"
              ></i>
            </a>
          </span>
        );
      }
      function getSmsIcon(phone) {
        return (
          <span
            className={`${commonStyles.circular} ${commonStyles.margin2OnLeft} ${commonStyles.orangeBackground}`}
          >
            <a href={"sms:" + phone} target="_blank" rel="noopener noreferrer">
              <i
                style={{ color: "blue" }}
                className="fa-2x fa-solid fa-comment-sms"
              ></i>
            </a>
          </span>
        );
      }
      function getWhatsappIcon(phone) {
        return (
          <span
            className={`${commonStyles.circular} ${commonStyles.margin2OnLeft} ${commonStyles.greenBackground}`}
          >
            <a
              href={"https://wa.me/" + phone}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i
                style={{ color: "white" }}
                className="fa-2x fa-brands fa-whatsapp"
              ></i>
            </a>
          </span>
        );
      }
      if (Object.keys(contactDetails).includes("phone")) {
        phone = contactDetails.phone;
        return (
          <>
            <div className={commonStyles.boxWithBorder}>
              <div style={{ flexGrow: "1" }}>
                <div>
                  <span>
                    <b>{textFor("phone") + ": "}</b>
                    {phone}
                  </span>
                </div>
                <div className={commonStyles.blankHeight2vh}></div>
                <div className={commonStyles.containerForEvenlySpacedChildren}>
                  {getPhoneCallIcon(phone)}
                  {getSmsIcon(phone)}
                  {getWhatsappIcon(phone)}
                </div>
                <div className={commonStyles.blankHeight2vh}></div>
              </div>
            </div>
          </>
        );
      }
    }
    return (
      <div>
        <b className={commonStyles.marginOnLeft}>
          {textFor("relation") + ": "}
        </b>
        {getRelationName(personDetails.familyRelationType)}
        <br />
        <b className={commonStyles.marginOnLeft}>
          {textFor("guardian") + ": "}
        </b>{" "}
        {personDetails.guardianName}
        <br />
        {getViewToShowPhoneDetailsIfAvailable(personDetails)}
        {getViewToShowEmailDetailsIfAvailable(personDetails)}
      </div>
    );
  }

  async function fetchContactDetails(memberId) {
    let url = BASE_URL + "/normalUser/contactDetails?memberId=" + memberId;

    const headers = {
      Authorization: `Bearer ${getAuthToken()}`,
    };

    try {
      setLoading(true);
      const response = await fetch(url, {
        method: "GET",
        headers: headers,
      });
      console.log(
        "response from fetch during fetchContactDetails = ",
        response
      );

      if (!response.ok) {
        if (response.status === 403) {
          console.log("got 403....");
          doLogout(dispatch, authActions);
        } else {
          throw json(
            {
              message: "Could not fetchContactDetails.",
            },
            {
              status: 500,
            }
          );
        }
      } else {
        console.log("got response ", response);
        const respData = await response.json();
        console.log("respData = ", respData);
        respData["memberId"] = personDetails.id;
        dispatch(fetchContactSuccess(respData));
        setContactDetails(respData);
      }
    } catch (error) {
      // Handle errors, including 403 Forbidden
      console.error("Error during fetchContactDetails :", error);
      const status = error.status;
      console.error("status = ", status);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <AlertDialog
          onOkClick={onClose}
          onBackdropClicked={onClose}
          title={textFor("toContact")}
          getContent={getContactDetailsView}
          level={"2"}
        />
      )}
    </>
  );
}

export async function fetchContactDetails() {
  //handle 400, 403
}
export default PersonContactDetails;
