import { LANGUAGE_KEY, USER_INPUT_TYPE } from "../Constants";
import {
  isAnEmail,
  isAPhoneNumber,
  isLikeAnEmail,
  isLikeAPhoneNumber,
} from "./fieldChecker";
import gotraTypeJson from "../jsonConstants/gotraTypes.json";
import { textFor } from "./languages";
import commonStyles from "../commonStyles.module.scss";
import PersonContactDetails from "../pages/searchGroom/PersonContactDetails";

const { gotraTypeOptions } = gotraTypeJson;
export function scrollTabToMakeFullyVisible(event) {
  const target = event.target.closest("button");
  const parentContainer = target.closest("button").closest("div"); // Get the parent container

  if (parentContainer) {
    const elementRect = target.getBoundingClientRect();
    const parentRect = parentContainer.getBoundingClientRect();

    // Calculate the amount to scroll
    let scrollAmount;
    if (elementRect.left < parentRect.left) {
      // Element is hidden on the left side
      scrollAmount = elementRect.left - parentRect.left;
      parentContainer.scrollTo({
        left: parentContainer.scrollLeft + scrollAmount,
        behavior: "smooth", // Use smooth scrolling if supported
      });
    } else if (elementRect.right > parentRect.right) {
      // Element is hidden on the right side
      scrollAmount = elementRect.right - parentRect.right;
      parentContainer.scrollTo({
        left: parentContainer.scrollLeft + scrollAmount,
        behavior: "smooth", // Use smooth scrolling if supported
      });
    } else {
      // Element is fully visible, no need to scroll.
      return;
    }
  }
}

export function getAge(m) {
  if (m.birthYear) {
    const currentYear = new Date().getFullYear();
    return currentYear - m.birthYear;
  }
  return "X";
}
export function getCurrentDateTimeFormatted() {
  const now = new Date();
  const istOffset = 5 * 60 + 30; // Offset in minutes
  const istTime = new Date(now.getTime() + istOffset * 60 * 1000);
  const formattedTime = istTime.toISOString().slice(0, 19).replace("T", " ");

  console.log("returning time = ", formattedTime);
  return formattedTime;
}
export function getCurrentDateTimeFormatted1() {
  const currentTime = new Date();
  const formattedDateTime = currentTime.toLocaleString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  });
  const hyphenSeparatedStr = formattedDateTime.split("/").join("-");
  console.log("returning time:", hyphenSeparatedStr);
  return hyphenSeparatedStr;
}
export function saveActiveRoute(activeRoute) {
  localStorage.setItem("activeRoute", activeRoute.pathname);
}
export function isAddressCityMissingInProfile() {
  const reduxUserProfile = JSON.parse(
    localStorage.getItem("reduxState_userProfile")
  );
  const shouldShowAlert =
    !Object.keys(reduxUserProfile).includes("addressCityName") ||
    reduxUserProfile.addressCityName === "";
  // if (shouldShowAlert) {
  //   window.alert(textFor("addressIsMissing"));
  // }
  return shouldShowAlert;
}

export function getMaskedEmail(email) {
  const [localPart, domain] = email.split("@");
  const maskedLocalPart =
    localPart.length > 4
      ? localPart.slice(0, 4) + "*".repeat(localPart.length - 4)
      : localPart;

  const domainParts = domain.split(".");
  const lastPart = domainParts.pop();
  const maskedDomain = "*".repeat(domainParts.pop().length);

  const maskedEmail = `${maskedLocalPart}@${maskedDomain}.${lastPart}`;
  return maskedEmail;
}

export function getMaskedPhone(phone) {
  const lastFour = phone.slice(-4);
  const maskedNumber = "*".repeat(9) + lastFour;
  return maskedNumber;
}

export function saveMaskedPhoneOrEmail(userInput) {
  if (isLikeAPhoneNumber(userInput)) {
    if (isAPhoneNumber(userInput)) {
      localStorage.setItem(USER_INPUT_TYPE, "phone");
      localStorage.setItem("phoneOrEmail", getMaskedPhone(userInput));
    }
  } else if (isLikeAnEmail(userInput)) {
    if (isAnEmail(userInput)) {
      localStorage.setItem(USER_INPUT_TYPE, "email");
      localStorage.setItem("phoneOrEmail", getMaskedEmail(userInput));
    }
  }
}

export function startsWith(str, searchString) {
  // If the search string is longer than the main string, it can't be a prefix
  if (searchString.length > str.length) {
    return false;
  }

  // Compare characters one by one
  for (let i = 0; i < searchString.length; i++) {
    if (str[i] !== searchString[i]) {
      return false;
    }
  }

  return true;
}

export function getFixedFullName(m) {
  let fullName = m.fullName;
  if (fullName && fullName.length > 20) {
    fullName = fullName.slice(0, 20);
  }
  return fullName;
}

function getGotraNormalCase(m) {
  if (m.gotraType) {
    const str = m.gotraType + "";
    return str.charAt(0) + str.slice(1).toLowerCase();
  } else {
    return "X";
  }
}

export function getGotraName(gotraStr) {
  const activeLanguage = localStorage.getItem(LANGUAGE_KEY) || "ENGLISH";
  let retVal = "";
  if (gotraStr) {
    const matchedRow = gotraTypeOptions.filter((g) => g.id === gotraStr);
    retVal =
      activeLanguage === "ENGLISH"
        ? matchedRow[0].name
        : matchedRow[0].hindiName;
  }
  return retVal;
}
export function getGotraAndAge(m) {
  return (
    <div>
      <p>{(getGotraName(m.gotraType) || "X") + ", " + getAge(m)}</p>
    </div>
  );
}

export const copySiteEmailId = async () => {
  copyInfoOnClipboard("info@yadavahir.com", "emailIdIsCopied");
};

export const copySitePhoneNumber = async () => {
  copyInfoOnClipboard("+918717911915", "phoneNumberIsCopied");
};

export const copyTextToClipboard = async (text) => {
  copyInfoOnClipboard(text, "messageIsCopied");
};

export const copyInfoOnClipboard = async (text, msgKey) => {
  try {
    await navigator.clipboard.writeText(text);
    alert(textFor(msgKey));
  } catch (err) {
    console.error("Failed to copy: ", err);
    alert("Failed to Copy.");
  }
};

export function getViewToShowPossibleDelayInProcess() {
  return (
    <>
      <hr />
      <div style={{ paddingRight: "5vw" }}>
        {textFor("theProcessMayTakeFewMinutes")}
      </div>
    </>
  );
}

export function clearUserInputsUsedForRegistration() {
  localStorage.removeItem("otp");
  localStorage.removeItem(USER_INPUT_TYPE);
}

export function getViewForContactButton(setContactDetailsDialogVisibility) {
  return (
    <button
      className={commonStyles.greenButton}
      onClick={() => {
        setContactDetailsDialogVisibility("1");
      }}
      type="button"
      id="contactToPersonBtn"
    >
      {textFor("contact")}
    </button>
  );
}

export function ifNeedsShowPersonContactDetailDialog(
  shouldShowContactDetailDialog,
  setContactDetailsDialogVisibility,
  personToViewDetail
) {
  function hideContactDetailsDialog() {
    setContactDetailsDialogVisibility("");
  }
  if (shouldShowContactDetailDialog) {
    return (
      <PersonContactDetails
        onClose={hideContactDetailsDialog}
        personDetails={personToViewDetail}
      />
    );
  } else {
    return "";
  }
}
