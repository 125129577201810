import React from "react";
import ReactDOM from "react-dom";
import Card from "../Card";
import classes from "../ErrorModal.module.scss";
import { BackDrop } from "../ErrorModal";
import Button from "../Button";
export const ModalOverlay = ({
  title,
  getContent,
  onOkClick,
  okButtonText = "OK",
  cancelButtonText,
  onCancelClick,
  level = "1",
}) => {
  let modalClass = classes.modal;
  let dialogTitleId = "dialogTitle";
  let alertOkButtonId = "alertOkButton";
  let alertCancelButtonId = "alertCancelButton";
  if (level == "2") {
    modalClass = classes.modal2;
    dialogTitleId = "dialogTitle2";
    alertOkButtonId = "alertOkButton2";
    alertCancelButtonId = "alertCancelButton2";
  }
  return (
    <Card className={modalClass}>
      <header className={classes.header}>
        <h2 id={dialogTitleId}>{title}</h2>
      </header>
      <div className={classes.content}>{getContent()}</div>
      <footer className={classes.action}>
        <Button
          id={alertOkButtonId}
          style={{ padding: "0 40px" }}
          onClick={onOkClick}
          autoFocus={true}
        >
          {okButtonText}
        </Button>
        {cancelButtonText ? (
          <Button
            id={alertCancelButtonId}
            onClick={onCancelClick}
            autoFocus={true}
          >
            {cancelButtonText}
          </Button>
        ) : (
          ""
        )}
      </footer>
    </Card>
  );
};
export function getBackdropView(onBackdropClicked, level = "1") {
  return ReactDOM.createPortal(
    <BackDrop onConfirm={onBackdropClicked} level={level} />,
    document.getElementById("backdrop-root")
  );
}
function AlertDialog({
  onOkClick,
  onBackdropClicked,
  title,
  getContent,
  okButtonText = "OK",
  cancelButtonText = "",
  onCancelClick = (e) => {},
  level = "1",
}) {
  // console.log("level in AlertDialog=" + level);
  return (
    <div>
      {getBackdropView(onBackdropClicked, level)}
      {ReactDOM.createPortal(
        <ModalOverlay
          title={title}
          getContent={getContent}
          onOkClick={onOkClick}
          okButtonText={okButtonText}
          cancelButtonText={cancelButtonText}
          onCancelClick={onCancelClick}
          level={level}
        />,
        document.getElementById("overlay-root")
      )}
    </div>
  );
}
export default AlertDialog;
