import commonStyles from "../../commonStyles.module.scss";
import { textFor } from "../../util/languages";
function News() {
  return (
    <div className={commonStyles.centeredMessageContainer}>
      <div className={commonStyles.centeredMessage}>
        {textFor("comingSoon")}
      </div>
    </div>
  );
}

export default News;
