import commonStyles from "../../../../../commonStyles.module.scss";
import { doLogout, getAuthToken } from "../../../../../util/auth";
import { BASE_URL } from "../../../../../Constants";
import { json, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../../../../store/reducers/authSlice";
import { textFor } from "../../../../../util/languages";
import { useState } from "react";
import AlertDialog from "../../../../UI/alertDialog/AlertDialog";
import {
  copyTextToClipboard,
  copySitePhoneNumber,
  getMaskedPhone,
  getViewToShowPossibleDelayInProcess,
} from "../../../../../util/viewUtils";
import { Loader } from "../../../../UI/ErrorModal";
import { clearPendingPhone } from "../../../../../store/reducers/userProfileSlice";
const PendingPhone = () => {
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState("");
  const [showPhoneVerification, setShowPhoneVerification] = useState("");
  const pendingPhone = useSelector((state) => state.userProfile.pendingPhone); // Select the pendingPhone state
  const pendingPhoneOtp = useSelector(
    (state) => state.userProfile.pendingPhoneOtp
  );
  const navigate = useNavigate();
  // console.log("In PendingVerificationphone mail is=", pendingPhone);
  const onDelete = async (e) => {
    if (pendingPhone !== "") {
      const confirmed = window.confirm(textFor("areYouSure"));
      if (confirmed) {
        await removePendigPhoneFromUserProfile();
        dispatch(clearPendingPhone());
      }
    }
  };

  const removePendigPhoneFromUserProfile = async () => {
    try {
      setShowLoader("1");
      const token = getAuthToken();
      const phoneOrEmail = "phone";
      let url =
        BASE_URL +
        "/normalUser/newLoginMethod?phoneOrEmail=" +
        encodeURIComponent(phoneOrEmail);
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const response = await fetch(url, {
        method: "DELETE",
        headers: headers,
      });
      console.log("response from delete newLoginMethod = ", response);

      if (!response.ok) {
        if (response.status === 403) {
          doLogout(dispatch, authActions);
        } else {
          throw json(
            { message: "Could not delete newLoginMethod." },
            {
              status: 500,
            }
          );
        }
      } else {
        console.log("successfully deleted newLogin method");
      }
    } catch (error) {
      // Handle errors, including 403 Forbidden
      console.error("Error during profile fetch:", error);
    } finally {
      setShowLoader("");
    }
  };

  function showPhoneVerificationStepsDialog() {
    setShowPhoneVerification("1");
  }
  function hidePhoneVerificationStepsDialog() {
    setShowPhoneVerification("");
  }
  function getTextForPhone() {
    return textFor("yourNewPhoneIs") + pendingPhone;
  }
  function getVerificationDialogView() {
    console.log("pendingOtp=" + pendingPhoneOtp);
    const msgText = "C " + pendingPhoneOtp;
    return (
      <>
        <span>{textFor("yourPhoneChangeRequestIsAccepted")}</span>
        <br />
        <span>{getTextForPhone()}</span>
        <br />
        <span>{textFor("sendOtpOnPhone1")}</span>
        <br />
        <span>{textFor("quicklyAfterPhoneRequest")}</span>
        <br />
        <span
          onClick={copySitePhoneNumber}
          className={commonStyles.sitePhoneEmailHighlighted}
        >
          +918717911915
        </span>
        <span>{textFor("sendOtpOnPhone2")}</span>
        <div className={commonStyles.marginOnTop}>
          <span>{textFor("sendOtpOnPhone3")} </span>
          <span
            className={commonStyles.msgContentHighlighted}
            onClick={() => {
              copyTextToClipboard(msgText);
            }}
          >
            {msgText}
          </span>
          <br />
          <br />
          <span>{textFor("use")}</span>
          <a
            href={`sms:+918717911915?body=${encodeURIComponent(msgText)}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className={commonStyles.smsHighlighted}>SMS</span>
          </a>
          {textFor("or")}
          <a
            href={`https://wa.me/+918717911915?text=${encodeURIComponent(
              msgText
            )}`}
            target="_blank"
          >
            <span className={commonStyles.whatsAppHighlighted}>WhatsApp</span>
          </a>

          <br />

          {getViewToShowPossibleDelayInProcess()}
        </div>
      </>
    );
  }

  function ifNeedsBeShowPhoneVerifiationStepsDialog() {
    if (showPhoneVerification) {
      return (
        <AlertDialog
          onOkClick={hidePhoneVerificationStepsDialog}
          onBackdropClicked={hidePhoneVerificationStepsDialog}
          title={textFor("verificationSteps")}
          getContent={getVerificationDialogView}
          okButtonText="OK"
        />
      );
    } else {
      return "";
    }
  }

  return (
    <>
      {showLoader && <Loader />}
      {ifNeedsBeShowPhoneVerifiationStepsDialog()}
      <div>
        <div id="pendingPhoneHeading" className={commonStyles.pendingHeading}>
          {textFor("pendingNewPhone:")}
        </div>
        <p id="pendingPhone" className={commonStyles.pendingField}>
          {pendingPhone}
        </p>
        <div style={{ marginTop: "1vh" }}>
          <button
            id="pendingPhoneVerifyButton"
            className={commonStyles.blueButton}
            onClick={showPhoneVerificationStepsDialog}
          >
            {textFor("verify")}
          </button>
          <button
            id="pendingPhoneDeleteButton"
            className={commonStyles.darkRedButton}
            onClick={onDelete}
          >
            {textFor("delete")}
          </button>
        </div>
      </div>
      <br />
      <hr />
    </>
  );
};
export default PendingPhone;

//todo email value abc5@b.comm was rejected, is because ending with comm ??

//todo use a user lock mechanism if there are too many login
//attempts, the user will be unlocked after 24 hours or after he
//sends unlock from their phone or email
