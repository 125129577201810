import { createSlice } from "@reduxjs/toolkit";
import { loadState } from "../../util/localStorageReader";

let oldSavedContactDetails = [];
const stateBeforeRefresh = loadState();
if (Object.keys(stateBeforeRefresh).includes("contactDetails")) {
  let contacDetails = stateBeforeRefresh.contactDetails;
  if (Object.keys(contacDetails).includes("records")) {
    if (Object.keys(contacDetails.records).length > 0) {
      oldSavedContactDetails = contacDetails.records;
    }
  }
}

const contactDetailsSlice = createSlice({
  name: "contactDetails",
  initialState: { records: [...oldSavedContactDetails] },
  reducers: {
    fetchContactSuccess(state, action) {
      const contactRecord = action.payload;
      console.log(
        "inside fetchContactSuccess,actionPayload = ",
        action.payload
      );
      const existingRecord = state.records.find(
        (r) => r.memberId === contactRecord.memberId
      );
      if (existingRecord == null) {
        state.records.push(contactRecord);
      }
    },
    clearContactRecords(state, action) {
      state.records = [];
      stateBeforeRefresh.contactDetails = null;
      oldSavedContactDetails = [];
    },
  },
});

export default contactDetailsSlice.reducer;
export const { fetchContactSuccess, clearContactRecords } =
  contactDetailsSlice.actions;
