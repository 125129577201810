import { useEffect, useState } from "react";
import { Form, json, Link, useNavigation } from "react-router-dom";
import {
  containsDot,
  containsOnlyDigits,
  isAnEmail,
  isAPhoneNumber,
  isAValidPassword,
  isLikeAnEmail,
  isLikeAPhoneNumber,
  isValidOtp,
  removeDot,
} from "../../util/fieldChecker";
import { BASE_URL, USER_INPUT_TYPE } from "../../Constants";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import commonStyles from "../../commonStyles.module.scss";
import { textFor } from "../../util/languages";
import { Loader } from "../../components/UI/ErrorModal";
import {
  copyTextToClipboard,
  copySiteEmailId,
  copySitePhoneNumber,
  getViewToShowPossibleDelayInProcess,
  saveMaskedPhoneOrEmail,
} from "../../util/viewUtils";
import HelpTermsAndPrivacyPolicyView from "../HelpTermsAndPrivacyPolicyView";

function Register() {
  const navigation = useNavigation();
  const [phoneOrEmail, setPhoneOrEmail] = useState("");
  const [termsChecked, setTermsChecked] = useState("");
  const [eyeOn, setEyeOn] = useState("");
  // const [password, setPassword] = useState("welcome");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const isSubmitting = navigation.state === "submitting";
  const [isOtpAvailable, setIsOtpAvailable] = useState("");
  useEffect(() => {
    const savedOtp = localStorage.getItem("otp");
    if (savedOtp != null && savedOtp.length === 6) {
      setIsOtpAvailable("1");
      setOtp(localStorage.getItem("otp"));
    }
  }, [isSubmitting]);
  const handlePhoneOrEmailChange = (e) => {
    setPhoneOrEmail(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleOtpChange = (e) => {
    console.log("onChange");
    let str = e.target.value;
    if (containsDot(str)) {
      str = removeDot(str);
    }
    if (str.length <= 6 && containsOnlyDigits(str)) {
      setOtp(str);
    }
  };

  function getTextForEmail() {
    const savedPhoneOrEmail = localStorage.getItem("phoneOrEmail");
    return textFor("yourEmailIs") + savedPhoneOrEmail;
  }

  function getTextForPhone() {
    const savedPhoneOrEmail = localStorage.getItem("phoneOrEmail");
    return textFor("yourPhoneIs") + savedPhoneOrEmail;
  }

  function getVerificationStepsForEmail(subjectText) {
    return (
      <div className={commonStyles.marginOnLeft}>
        <div>{textFor("registrationStarted")}</div>
        <div>{getTextForEmail()}</div>
        <span>{textFor("sendOtpOnEmail1")}</span>
        <br />
        <span>{textFor("quicklyAfterEmailRequest")}</span>
        <br />
        <span
          onClick={copySiteEmailId}
          className={commonStyles.sitePhoneEmailHighlighted}
        >
          info@yadavahir.com
        </span>
        <span>{textFor("sendOtpOnEmail2")}</span>
        <br />
        <span>
          {textFor("sendOtpOnEmail3")}
          <span
            className={commonStyles.msgContentHighlighted}
            onClick={() => {
              copyTextToClipboard(subjectText);
            }}
          >
            {subjectText}
          </span>
        </span>
        <br />
        <span>{textFor("sendOtpOnEmail4")}</span>
        <br />

        <span style={{ display: "inline-flex", alignItems: "center" }}>
          {textFor("use")}

          <span className={commonStyles.emailButtonHighlighted}>
            <a
              href={`mailto:info@yadavahir.com?subject=${encodeURIComponent(
                subjectText
              )}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              email <i class="fa-regular fa-lg fa-envelope"></i>
            </a>
          </span>
        </span>
        {getViewToShowPossibleDelayInProcess()}
      </div>
    );
  }

  function getVerificationStepsForPhone(msgText) {
    return (
      <div className={commonStyles.marginOnLeft}>
        <div>{textFor("registrationStarted")}</div>
        <div>{getTextForPhone()}</div>
        <span>{textFor("sendOtpOnPhone1")}</span>
        <br />
        <span>{textFor("quicklyAfterPhoneRequest")}</span>
        <br />
        <span
          onClick={copySitePhoneNumber}
          className={commonStyles.sitePhoneEmailHighlighted}
        >
          +918717911915
        </span>
        <span>{textFor("sendOtpOnPhone2")}</span>
        <br />
        <div className={commonStyles.marginOnTop}>
          <span>{textFor("sendOtpOnPhone3")}</span>
          <span
            className={commonStyles.msgContentHighlighted}
            onClick={() => {
              copyTextToClipboard(msgText);
            }}
          >
            {msgText}
          </span>
          <br />
          <br />
          <span>{textFor("use")}</span>

          <a
            href={`sms:+918717911915?body=${encodeURIComponent(msgText)}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className={commonStyles.smsHighlighted}>SMS</span>
          </a>
          {textFor("or")}
          <a
            href={`https://wa.me/+918717911915?text=${encodeURIComponent(
              msgText
            )}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className={commonStyles.whatsAppHighlighted}>WhatsApp</span>
          </a>
        </div>

        {getViewToShowPossibleDelayInProcess()}
      </div>
    );
  }
  function getTextToSendOtp() {
    const msgText = "A " + localStorage.getItem("otp");

    if (localStorage.getItem(USER_INPUT_TYPE) === "email") {
      return getVerificationStepsForEmail(msgText);
    } else {
      return getVerificationStepsForPhone(msgText);
    }
  }

  function resetClicked(e) {
    if (window.confirm(textFor("areYouSure"))) {
      localStorage.removeItem("otp");
      localStorage.removeItem(USER_INPUT_TYPE);
      setIsOtpAvailable("");
      setOtp("");
      setPhoneOrEmail("");
      setPassword("");
    }
  }
  const getRegisterationForm = () => {
    return (
      <Form method="post" noValidate>
        <div>
          <label className={commonStyles.fieldLabel} htmlFor="phoneOrEmail">
            {textFor("phoneOrEmail:")}
          </label>
          <br />
          <input
            className={commonStyles.inputField}
            type="text"
            id="phoneOrEmail"
            autoComplete="off"
            name="phoneOrEmail"
            placeholder={textFor("emailPhonePlaceholder")}
            value={phoneOrEmail}
            onChange={handlePhoneOrEmailChange}
          />

          <div style={{ marginTop: "2vh" }}>
            <label
              style={{ display: "inline" }}
              className={commonStyles.fieldLabel}
              htmlFor="password"
            >
              {textFor("password:")}
            </label>
            <div>
              <input
                style={{ position: "relative" }}
                className={commonStyles.inputField}
                type={eyeOn === "" ? "password" : "text"}
                id="password"
                value={password}
                name="password"
                autoComplete="off"
                placeholder={textFor("passwordPlaceholder")}
                onChange={handlePasswordChange}
              />
              <span id="eyeIcon" className={commonStyles.eyeIcon}>
                <span
                  onClick={() => {
                    setEyeOn((prevState) => {
                      return prevState === "" ? "1" : "";
                    });
                  }}
                >
                  {eyeOn === "" ? <FaEye /> : <FaEyeSlash />}
                </span>
              </span>
            </div>
          </div>

          <div style={{ marginTop: "2vh" }}>
            <label className={commonStyles.fieldLabel} htmlFor="otp">
              {textFor("otp")}
            </label>
            <br />
            <input
              className={commonStyles.inputField}
              type="text"
              id="otp"
              value={otp}
              autoComplete="off"
              maxLength="6"
              name="otp"
              placeholder={textFor("any6DigitNumber")}
              onChange={handleOtpChange}
            />
          </div>
        </div>
        <br />
        <div style={{ paddingLeft: "1vw" }}>
          <label style={{ color: "white" }}>
            <input
              type="checkbox"
              checked={termsChecked}
              onChange={() => {
                setTermsChecked((prevState) => {
                  if (prevState !== "") {
                    localStorage.removeItem("termsChecked");
                    return "";
                  } else {
                    localStorage.setItem("termsChecked", "1");
                    return "1";
                  }
                });
              }}
            />
            {textFor("IHaveCarefullyReadPolicyAndTerms")}
          </label>
        </div>
        <button
          className={commonStyles.pageSubmitBtn}
          type="submit"
          disabled={isSubmitting}
        >
          {textFor("registerButton")}
        </button>
      </Form>
    );
  };

  function getPageTitle() {
    if (!isOtpAvailable) {
      return textFor("registration");
    } else {
      return textFor("verification");
    }
  }
  return (
    <div className={commonStyles.pageBackground}>
      {isSubmitting && <Loader />}

      <h2 className={commonStyles.pageTitle}>{getPageTitle()}</h2>
      {!isOtpAvailable && getRegisterationForm()}
      <div>
        {isOtpAvailable && (
          <div>
            {getTextToSendOtp()}
            <button
              id="resetButton"
              className={`${commonStyles.pageSubmitBtn} ${commonStyles.resetButton}`}
              type="button"
              onClick={resetClicked}
            >
              {textFor("reset")}
            </button>
          </div>
        )}
      </div>
      <div className={commonStyles.bottomSection}>
        <p style={{ color: "white" }}>
          {textFor("alreadyHaveAnAccount?")}{" "}
          <Link style={{ color: "blue", marginLeft: "2vw" }} to="/login">
            {textFor("login")}
          </Link>
        </p>
        <br />
      </div>

      <HelpTermsAndPrivacyPolicyView />
    </div>
  );
}
export default Register;

export async function action({ request, params }) {
  if (!localStorage.getItem("termsChecked")) {
    alert(textFor("pleaseAcceptTermsAndPolicy"));
    return "";
  }
  console.log("register action is called.. request = ", request);
  const data = await request.formData();
  console.log("register action is called.. data = ", data);
  let registrationData = {};
  const userInput = data.get("phoneOrEmail").trim();
  const otp = data.get("otp").trim();
  const password = data.get("password").trim();
  let url = BASE_URL + "/normalUser/";

  if (isLikeAPhoneNumber(userInput)) {
    if (!isAPhoneNumber(userInput)) {
      return null;
    } else {
      registrationData = {
        phone: userInput,
      };
      url += "registerWithPhone";
    }
  } else if (isLikeAnEmail(userInput)) {
    if (!isAnEmail(userInput)) {
      return null; //alert will be shown while checking in isAPhoneNumber() and isAnEmail()
    } else {
      registrationData = {
        email: userInput,
      };
      url += "registerWithEmail";
    }
  } else {
    alert(textFor("badCredentials"));
  }

  if (!isAValidPassword(password) || !isValidOtp(otp)) {
    return null;
  }
  registrationData.otp = otp;
  registrationData.password = password;

  const response = await fetch(url, {
    method: request.method,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(registrationData),
  });

  if (!response.ok) {
    if (response.status === 400) {
      const errorResponse = await response.json();
      if (Object.keys(errorResponse).includes("errorMessage")) {
        console.log("errorMessage = " + errorResponse.errorMessage);
        if (errorResponse.errorMessage.includes("already registered")) {
          window.alert(textFor("userIsAlreadyRegistered"));
          return "";
        } else if (errorResponse.errorMessage.includes("DUPLICATE-ENTRY")) {
          window.alert(textFor("useDifferentOtp"));
          return "";
        }
      }
    }
    throw json({ message: "Could not register." }, { status: 500 });
  }

  saveMaskedPhoneOrEmail(userInput);
  localStorage.setItem("otp", otp);

  return "";
}
