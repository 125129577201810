import ImageButton from "../../components/UI/imageButton/ImageButton";
import familyIconImageUrl from "../../assets/familyIcon.png";
import friendsIconImageUrl from "../../assets/friendsIcon.png";
import searchBrideIconImageUrl from "../../assets/searchBrideIcon.png";
import searchGroomIconImageUrl from "../../assets/searchGroomIcon.png";
import helpIconImageUrl from "../../assets/helpIcon.png";
import settingIconImageUrl from "../../assets/settingsIcon.png";
import styles from "./Dashboard.module.scss";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LANGUAGE_KEY, SEARCH_GENDER, USER_INPUT_TYPE } from "../../Constants";
import { Loader } from "../../components/UI/ErrorModal";
import { doNavigate } from "../../util/auth";
import {
  clearUserInputsUsedForRegistration,
  isAddressCityMissingInProfile,
  saveActiveRoute,
} from "../../util/viewUtils";
import { useDispatch } from "react-redux";
import { loadRegistrationDetails } from "../ProfileLoader";
import { textFor } from "../../util/languages";
import AlertDialog from "../../components/UI/alertDialog/AlertDialog";
function Dashboard() {
  const navigate = useNavigate();
  const activeRoute = useLocation();
  saveActiveRoute(activeRoute);
  //to make sure we show image first and then the button it
  const [familyIconLoaded, setFamilyIconLoaded] = useState("");
  const [friendIconLoaded, setFriendIconLoaded] = useState("");
  const [groomIconLoaded, setGroomIconLoaded] = useState("");
  const [brideIconLoaded, setBrideIconLoaded] = useState("");
  const [settingIconLoaded, setSettingIconLoaded] = useState("");
  const [helpIconLoaded, setHelpIconLoaded] = useState("");
  const [profileLoaded, setProfileLoaded] = useState("");
  const [cityMissingDialogVisibility, setCityMissingDialogVisibility] =
    useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    async function loadProfileIfNotAvailable() {
      if (!localStorage.getItem("addressChecked")) {
        await loadRegistrationDetails(dispatch, { isDirty: 1, id: "" });
        localStorage.setItem("addressChecked", 1);
      }
      setProfileLoaded("1");
      clearUserInputsUsedForRegistration();
    }
    loadProfileIfNotAvailable();
  }, []);

  function atLeaseOneIconLoading() {
    return !(
      familyIconLoaded &&
      friendIconLoaded &&
      groomIconLoaded &&
      brideIconLoaded &&
      settingIconLoaded &&
      helpIconLoaded &&
      profileLoaded
    );
  }
  function decodeToken() {
    const token = localStorage.getItem("token");
    const arrayToken = token.split(".");
    // console.log("arrayToken=", JSON.parse(atob(arrayToken[1])));
    const tokenPayload = JSON.parse(atob(arrayToken[1]));
    // console.log("tokenPayload = ", tokenPayload);
    localStorage.setItem("userId", tokenPayload.userId);
    localStorage.setItem("selfMemberId", tokenPayload.selfMemberId);
    localStorage.setItem(LANGUAGE_KEY, tokenPayload.userLanguage);
  }

  // after click, the browser back button is having history ??
  //but no problem in that, looks like it is due to automatic redirect
  //wen user is logged in

  const familyIconClicked = (event) => {
    if (isAddressCityMissingInProfile()) {
      setCityMissingDialogVisibility("1");
    } else {
      doNavigate(navigate, "/familyMembers");
    }
  };
  const friendsIconClicked = (event) => {
    if (isAddressCityMissingInProfile()) {
      setCityMissingDialogVisibility("1");
    } else {
      doNavigate(navigate, "/friends");
    }
  };
  const searchGroomIconClicked = (event) => {
    if (isAddressCityMissingInProfile()) {
      setCityMissingDialogVisibility("1");
    } else {
      localStorage.setItem(SEARCH_GENDER, "MALE");
      doNavigate(navigate, "/searchGroom");
    }
  };
  const searchBrideIconClicked = (event) => {
    if (isAddressCityMissingInProfile()) {
      setCityMissingDialogVisibility("1");
    } else {
      localStorage.setItem(SEARCH_GENDER, "FEMALE");
      doNavigate(navigate, "/searchGroom");
    }
  };
  const settingIconClicked = (event) => {
    doNavigate(navigate, "/settings");
  };
  const helpIconClicked = (event) => {
    doNavigate(navigate, "/help");
  };
  function getFamilyButton() {
    return (
      <div className={styles.familyBtnContainer}>
        <ImageButton
          id="familyMembers"
          imageUrl={familyIconImageUrl}
          onClick={familyIconClicked}
          onImageLoad={setFamilyIconLoaded}
          buttonName="family"
        />
        {familyIconLoaded && (
          <div className={styles.familyBtnTextContainer}>
            <div className={styles.familyBtnText}>{textFor("family")}</div>
          </div>
        )}
      </div>
    );
  }
  function getHelpButton() {
    return (
      <div className={styles.familyBtnContainer}>
        <ImageButton
          id="help"
          imageUrl={helpIconImageUrl}
          onClick={helpIconClicked}
          onImageLoad={setHelpIconLoaded}
          buttonName="help"
        />
        {helpIconLoaded && (
          <div className={styles.familyBtnTextContainer}>
            <div className={styles.helpBtnText}>{textFor("help")}</div>
          </div>
        )}
      </div>
    );
  }
  function getSettingsButton() {
    return (
      <div
        className={`${styles.familyBtnContainer} ${styles.familyBtnBkColor}`}
      >
        <ImageButton
          id="settings"
          imageUrl={settingIconImageUrl}
          onClick={settingIconClicked}
          onImageLoad={setSettingIconLoaded}
          buttonName="settings"
        />
        {settingIconLoaded && (
          <div className={styles.familyBtnTextContainer}>
            <div className={styles.settingsBtnText}>{textFor("settings")}</div>
          </div>
        )}
      </div>
    );
  }
  function getSearchBrideButton() {
    return (
      <div className={styles.familyBtnContainer}>
        <ImageButton
          id="searchBride"
          imageUrl={searchBrideIconImageUrl}
          onClick={searchBrideIconClicked}
          onImageLoad={setBrideIconLoaded}
          buttonName="searchBride"
        />
        {brideIconLoaded && (
          <div className={styles.familyBtnTextContainer}>
            <div className={styles.brideBtnText}>{textFor("searchBride")}</div>
          </div>
        )}
      </div>
    );
  }
  function getSearchGroomButton() {
    return (
      <div className={styles.familyBtnContainer}>
        <ImageButton
          id="searchGroom"
          imageUrl={searchGroomIconImageUrl}
          onClick={searchGroomIconClicked}
          onImageLoad={setGroomIconLoaded}
          buttonName="searchGroom"
        />
        {groomIconLoaded && (
          <div className={styles.familyBtnTextContainer}>
            <div className={styles.groomBtnText}>{textFor("searchGroom")}</div>
          </div>
        )}
      </div>
    );
  }
  function getFriendsButton() {
    return (
      <div className={styles.familyBtnContainer}>
        <ImageButton
          id="friends"
          imageUrl={friendsIconImageUrl}
          onClick={friendsIconClicked}
          onImageLoad={setFriendIconLoaded}
          buttonName="friends"
        />
        {friendIconLoaded && (
          <div className={styles.familyBtnTextContainer}>
            <div className={styles.friendsBtnText}>{textFor("friends")}</div>
          </div>
        )}
      </div>
    );
  }
  function hideMissingAddressDialog() {
    setCityMissingDialogVisibility("");
  }
  function getMissingAddressCityView() {
    return <div>{textFor("pleaseProvideAddressCity")}</div>;
  }
  function showMissingAddressDialog() {
    return (
      <AlertDialog
        onOkClick={hideMissingAddressDialog}
        onBackdropClicked={hideMissingAddressDialog}
        title={textFor("cityIsRequired")}
        getContent={getMissingAddressCityView}
        okButtonText="OK"
      />
    );
  }
  decodeToken();
  return (
    <div className={styles.dashboardTopLevelContainer}>
      {cityMissingDialogVisibility && showMissingAddressDialog()}
      {atLeaseOneIconLoading() && <Loader />}
      <div className={styles.dashboardContainer}>
        {getSearchGroomButton()}
        {getSearchBrideButton()}
        {getFamilyButton()}
        {getFriendsButton()}
        {getSettingsButton()}
        {getHelpButton()}
      </div>
    </div>
  );
}

export default Dashboard;

//todo: for friend also we need address so prompt user to provide that before friend req check
//todo: test for more number of records around 10k
//todo: test with bigger names of persons
//todo: in the friend search result the member grid should show gotra and age like other places
