import { NavLink, useLocation, useNavigate } from "react-router-dom";
import classes from "./MainNavigation.module.scss";
import { useRef, useState } from "react";
import { textFor } from "../util/languages";
import { Loader } from "../components/UI/ErrorModal";
import AlertDialog from "../components/UI/alertDialog/AlertDialog";

function MainNavigation() {
  const navigate = useNavigate();
  const [showNotifictionDialog, setNotificationVisibility] = useState("");
  const activeRoute = useLocation();
  const burgerCheckRef = useRef(null);
  // console.log("in MainNavigation");
  function blankAreaClicked(e) {
    const targetStr = "" + e.target;
    // console.log(
    //   "targetStr=",
    //   targetStr,
    //   "e.target.value=",
    //   e.target.value,
    //   "burgerRef.current.checked=",
    //   burgerCheckRef.current.checked
    // );
    if (
      targetStr.includes("HTMLDivElement") &&
      burgerCheckRef.current.checked === true
    ) {
      //that means side blank area is clicked, so uncheck the burger checkbox
      burgerCheckRef.current.checked = false;
    }
  }
  function getNotificationView() {
    function hideNotificationDialog() {
      setNotificationVisibility("");
    }
    function getNotiicationDialogView() {
      return (
        <>
          <br />
          {textFor("systemWillBeInMaintennance")}
          <br />
          <br />
        </>
      );
    }
    return (
      <AlertDialog
        onOkClick={hideNotificationDialog}
        onBackdropClicked={() => {
          setNotificationVisibility("1");
        }}
        title={textFor("importantNotice:")}
        getContent={getNotiicationDialogView}
        okButtonText="OK"
      />
    );
  }
  return (
    <div className={classes.sideMenuContainer} data-testid="sideMenuContainer">
      {showNotifictionDialog && getNotificationView()}
      <div className={classes.leftSection}>
        <div id="homeIconWrapper" className={classes.homeIconWrapper}>
          <span
            id="homeIcon"
            className={classes.homeIcon}
            onClick={() => {
              if (!activeRoute.pathname.includes("dashboard")) {
                navigate("/dashboard");
              }
            }}
          >
            <i
              style={{ color: "lightBlue" }}
              className="fa-solid fa-house fa-lg"
            ></i>
          </span>
        </div>
      </div>
      {/* <div className={classes.rightSection}>
        <div id="homeIconWrapper" className={classes.homeIconWrapper}>
          <span
            id="homeIcon"
            className={classes.homeIcon}
            onClick={() => {
              if (!activeRoute.pathname.includes("dashboard")) {
                navigate("/dashboard");
              }
            }}
          >
            <i
              style={{ color: "yellow" }}
              className="fa-solid fa-bell fa-lg"
            ></i>
          </span>
        </div>
      </div> */}
      <div onClick={blankAreaClicked} className={classes.sideMenuWrapper}>
        <label className={classes.hamburgerMenu} id="hamburgerMenu">
          <input ref={burgerCheckRef} type="checkbox" />
        </label>

        <aside className={classes.sidebar}>
          <nav>
            <ul className={classes.list}>
              <li>
                <NavLink
                  to="/dashboard"
                  className={({ isActive }) =>
                    isActive ? classes.active : undefined
                  }
                  end //to denote the ending of url path
                >
                  {textFor("dashboard")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/profile"
                  className={({ isActive }) =>
                    isActive ? classes.active : undefined
                  }
                >
                  {textFor("profile")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/settings"
                  className={({ isActive }) =>
                    isActive ? classes.active : undefined
                  }
                >
                  {textFor("settings")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/help"
                  className={({ isActive }) =>
                    isActive ? classes.active : undefined
                  }
                >
                  {textFor("help")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/news"
                  className={({ isActive }) =>
                    isActive ? classes.active : undefined
                  }
                >
                  {textFor("news")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/logout"
                  className={({ isActive }) =>
                    isActive ? classes.active : undefined
                  }
                >
                  {textFor("logout")}
                </NavLink>
              </li>
            </ul>
          </nav>
        </aside>
      </div>
    </div>
  );
}
export default MainNavigation;

//todo we can provide options to directly go to family/friend/searchbride-groom etc in navigation also
//but we will have to handle the addressMissing dialog.

//todo TOO MANY REQUESTS BUG: suppose we are on friends page where connected friends are 0.
//from drawer open profile and just press back and it is sending too many requests for friends
//but if we have at least 1 friend then it won't appear, so we should be good??
//SOLUTION DONE: in the FriendsAndRequests, I am now uing the useEffect hook to
//fetch data in the FriendsLoader, I'm just returning []
//so far it is looking good.

//todo when address is chosen, then we can provide the link to open in google map
//e.g. https://www.google.com/maps/place/dadra+and+Haveli,+state+name and it will show
//red border around the area.

//todo notification bell icon for new friend request, changes approved or rejected.
